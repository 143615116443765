$header-height: 60px;

$main-logo-url--horizontal: '//storage.googleapis.com/web-client-ttc/dist/img/logo/10kc_v2_horizontal.b3f46c78.svg';
$main-logo-url--icon: '//storage.googleapis.com/web-client-ttc/dist/img/logo/10kc_v2_icon.8f78a14e.svg';

/* Media Breakpoints */
$screen-xs: 480px;
$md-screen-xs: 600px;
$screen-sm: 768px;
$md-screen-sm: 960px;
$screen-md: 992px;
$screen-lg: 1200px;

$screen-xs-max: ($screen-sm - 1);
$screen-sm-max: ($screen-md - 1);
$screen-md-max: ($screen-lg - 1);
