@import '../../../design-system/src/stylesheets/init';

@import 'utils/variables';

@import 'base/mixins';
@import '../../../design-system/src/stylesheets/hyperlinks';
@import '../../../design-system/src/stylesheets/focus';
@import 'base/utilities';
@import '../../../design-system/src/stylesheets/utilities';

@import 'typography/typography';

@import 'utils/appearance';
@import 'utils/materials2-override';

@import '../../../design-system/src/stylesheets/material-theme';
@import '../../src/stylesheets/subheader';

html {
  @include m-backgroundColor-grey-50();
  height: 100%;
}

body {
  @include m-backgroundColor-grey-50();
  @include m-color-black();
  @include mdc-typography-styles-body2();
}

html,
body {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

img {
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

input {
  @include mdc-typography-styles-body2();
}

.grecaptcha-badge {
  visibility: hidden;
}
