html {
  --size-0: 0px;
  --size-0-rem: 0rem;
  --size-025: 1px;
  --size-025-rem: 0.0625rem;
  --size-050: 2px;
  --size-050-rem: 0.125rem;
  --size-100: 4px;
  --size-100-rem: 0.25rem;
  --size-150: 6px;
  --size-150-rem: 0.375rem;
  --size-200: 8px;
  --size-200-rem: 0.5rem;
  --size-300: 12px;
  --size-300-rem: 0.75rem;
  --size-400: 16px;
  --size-400-rem: 1rem;
  --size-500: 20px;
  --size-500-rem: 1.25rem;
  --size-600: 24px;
  --size-600-rem: 1.5rem;
  --size-800: 32px;
  --size-800-rem: 2rem;
  --size-1000: 40px;
  --size-1000-rem: 2.5rem;
  --size-1200: 48px;
  --size-1200-rem: 3rem;
  --size-1600: 64px;
  --size-1600-rem: 4rem;
  --size-2000: 80px;
  --size-2000-rem: 5rem;
  --size-2400: 96px;
  --size-2400-rem: 6rem;
  --size-2800: 112px;
  --size-2800-rem: 7rem;
  --size-3200: 128px;
  --size-3200-rem: 8rem;
  --size-750: 30px;
  --size-750-rem: 1.875rem;
  --size-550: 22px;
  --size-550-rem: 1.375rem;
  --size-450: 18px;
  --size-450-rem: 1.125rem;
  --size-350: 14px;
  --size-350-rem: 0.875rem;

  --space-0: var(--size-0);
  --space-025: var(--size-025);
  --space-050: var(--size-050);
  --space-100: var(--size-100);
  --space-150: var(--size-150);
  --space-200: var(--size-200);
  --space-300: var(--size-300);
  --space-400: var(--size-400);
  --space-500: var(--size-500);
  --space-600: var(--size-600);
  --space-800: var(--size-800);
  --space-1000: var(--size-1000);
  --space-1200: var(--size-1200);
  --space-1600: var(--size-1600);
  --space-2000: var(--size-2000);
  --space-2400: var(--size-2400);
  --space-2800: var(--size-2800);
  --space-3200: var(--size-3200);

  --border-radius-full: 99999px;
  --border-radius-full-rem: 6249.9375rem;
  --border-radius-0: var(--size-0);
  --border-radius-050: var(--size-050);
  --border-radius-100: var(--size-100);
  --border-radius-150: var(--size-150);
  --border-radius-200: var(--size-200);
  --border-radius-300: var(--size-300);
  --border-radius-400: var(--size-400);
  --border-radius-550: var(--size-550);
  --border-radius-750: var(--size-750);
  --border-width-025: var(--size-025);
  --border-width-050: var(--size-050);
  --border-width-100: var(--size-100);

  --color-grey-16: rgb(26 26 26);
  --color-grey-15: rgb(48 48 48);
  --color-grey-14: rgb(74 74 74);
  --color-grey-13: rgb(97 97 97);
  --color-grey-12: rgb(138 138 138);
  --color-grey-11: rgb(181 181 181);
  --color-grey-10: rgb(204 204 204);
  --color-grey-9: rgb(212 212 212);
  --color-grey-8: rgb(227 227 227);
  --color-grey-7: rgb(235 235 235);
  --color-grey-6: rgb(241 241 241);
  --color-grey-5: rgb(243 243 243);
  --color-grey-4: rgb(243 243 243);
  --color-grey-3: rgb(250 250 250);
  --color-grey-2: rgb(253 253 253);
  --color-grey-1: rgb(255 255 255);

  --color-blue-16: rgb(19 20 38);
  --color-blue-15: rgb(30 34 77);
  --color-blue-14: rgb(36 44 122);
  --color-blue-13: rgb(38 50 171);
  --color-blue-12: rgb(38 55 222);
  --color-blue-11: rgb(72 87 239);
  --color-blue-10: rgb(133 144 254);
  --color-blue-9: rgb(184 190 255);
  --color-blue-8: rgb(209 213 255);
  --color-blue-7: rgb(209 213 255);
  --color-blue-6: rgb(219 223 255);
  --color-blue-5: rgb(230 232 254);
  --color-blue-4: rgb(235 237 255);
  --color-blue-3: rgb(240 241 255);
  --color-blue-2: rgb(245 246 255);
  --color-blue-1: rgb(250 250 255);

  --color-teal-16: rgb(5 10 9);
  --color-teal-15: rgb(19 47 42);
  --color-teal-14: rgb(28 89 78);
  --color-teal-13: rgb(32 136 118);
  --color-teal-12: rgb(30 188 160);
  --color-teal-11: rgb(28 217 183);
  --color-teal-10: rgb(67 229 200);
  --color-teal-9: rgb(133 239 220);
  --color-teal-8: rgb(180 244 232);
  --color-teal-7: rgb(198 246 237);
  --color-teal-6: rgb(211 248 241);
  --color-teal-5: rgb(223 251 246);
  --color-teal-4: rgb(228 251 247);
  --color-teal-3: rgb(237 252 250);
  --color-teal-2: rgb(242 253 251);
  --color-teal-1: rgb(251 254 254);

  --color-orange-16: rgb(37 20 0);
  --color-orange-15: rgb(65 35 0);
  --color-orange-14: rgb(94 50 0);
  --color-orange-13: rgb(124 68 0);
  --color-orange-12: rgb(149 87 0);
  --color-orange-11: rgb(178 101 0);
  --color-orange-10: rgb(229 126 0);
  --color-orange-9: rgb(255 140 0);
  --color-orange-8: rgb(255 199 121);
  --color-orange-7: rgb(255 214 164);
  --color-orange-6: rgb(255 221 182);
  --color-orange-5: rgb(255 228 198);
  --color-orange-4: rgb(255 235 213);
  --color-orange-3: rgb(255 241 227);
  --color-orange-2: rgb(255 247 238);
  --color-orange-1: rgb(255 253 250);

  --color-red-16: rgb(47 10 4);
  --color-red-15: rgb(95 21 7);
  --color-red-14: rgb(142 31 11);
  --color-red-13: rgb(181 38 11);
  --color-red-12: rgb(229 50 0);
  --color-red-11: rgb(239 77 47);
  --color-red-10: rgb(253 129 122);
  --color-red-9: rgb(253 176 172);
  --color-red-8: rgb(254 195 193);
  --color-red-7: rgb(254 211 209);
  --color-red-6: rgb(254 218 217);
  --color-red-5: rgb(254 226 225);
  --color-red-4: rgb(254 233 232);
  --color-red-3: rgb(255 237 236);
  --color-red-2: rgb(255 246 246);
  --color-red-1: rgb(255 251 251);

  --color-green-16: rgb(13 42 9);
  --color-green-15: rgb(14 61 8);
  --color-green-14: rgb(20 81 12);
  --color-green-13: rgb(30 111 19);
  --color-green-12: rgb(52 132 41);
  --color-green-11: rgb(63 160 50);
  --color-green-10: rgb(46 211 96);
  --color-green-9: rgb(50 225 129);
  --color-green-8: rgb(53 238 152);
  --color-green-7: rgb(56 250 163);
  --color-green-6: rgb(99 253 176);
  --color-green-5: rgb(146 254 194);
  --color-green-4: rgb(180 254 210);
  --color-green-3: rgb(205 254 225);
  --color-green-2: rgb(227 255 237);
  --color-green-1: rgb(248 255 251);

  --space-default: var(--size-400);
  --space-2xsmall: var(--size-100);
  --space-xsmall: var(--size-200);
  --space-small: var(--size-300);
  --space-medium: var(--size-600);

  --border-radius-card: var(--border-radius-550);
  --border-radius-button: var(--border-radius-200);
  --border-radius-menu: var(--border-radius-300);
  --border-radius-inner-card: var(--border-radius-300);
  --border-radius-base: var(--border-radius-300);
  --border-radius-nested: var(--border-radius-100);

  body[data-theme='light'] {
    --color-text-default: var(--color-grey-16);
    --color-text-subdued: var(--color-grey-13);
    --color-text-subdued-hover: var(--color-grey-12);
    --color-text-disabled: var(--color-grey-12);
    --color-text-primary: var(--color-blue-12);
    --color-text-inverse: var(--color-grey-1);
    --color-text-critical: var(--color-red-11);
    --color-text-secondary: var(--color-teal-14);
    --color-text-warning: var(--color-orange-9);

    --color-background-bg-default: var(--color-grey-5);
    --color-background-bg-surface: var(--color-grey-1);
    --color-background-bg-surface-warning: var(--color-orange-4);
    --color-background-bg-surface-active: var(--color-grey-6);
    --color-background-bg-surface-hover: var(--color-grey-5);
    --color-background-bg-surface-selected: var(--color-grey-3);
    --color-background-bg-surface-subdued: var(--color-grey-6);
    --color-background-bg-surface-subdued-hover: var(--color-grey-4);
    --color-background-bg-surface-subdued-active: var(--color-grey-7);
    --color-background-bg-surface-subdued-selected: var(--color-grey-7);
    --color-background-bg-inverse: var(--color-grey-16);
    --color-background-bg-surface-primary: var(--color-blue-5);
    --color-background-bg-surface-primary-hover: var(--color-blue-2);
    --color-background-bg-surface-secondary: var(--color-teal-5);
    --color-background-bg-surface-secondary-hover: var(--color-teal-2);
    --color-background-bg-surface-critical: var(--color-red-4);
    --color-background-bg-surface-success: var(--color-green-2);

    --color-background-bg-fill: var(--color-grey-1);
    --color-background-bg-fill-primary: var(--color-blue-12);
    --color-background-bg-fill-secondary: var(--color-teal-14);
    --color-background-bg-fill-critical: var(--color-red-11);
    --color-background-bg-fill-disabled: var(--color-grey-8);

    --color-border-default: var(--color-grey-8);
    --color-border-hover: var(--color-grey-10);
    --color-border-disabled: var(--color-grey-7);
    --color-border-subdued: var(--color-grey-7);
    --color-border-warning: var(--color-red-9);
    --color-border-secondary: var(--color-teal-13);
    --color-border-primary: var(--color-blue-12);

    --color-icon-default: var(--color-grey-16);
    --color-icon-subdued: var(--color-grey-13);
    --color-icon-primary: var(--color-blue-12);
    --color-icon-secondary: var(--color-teal-13);
    --color-icon-success: var(--color-green-11);
    --color-icon-warning: var(--color-orange-10);
    --color-icon-critical: var(--color-red-12);
    --color-icon-disabled: var(--color-grey-12);
  }

  body[data-theme='dark'] {
    --color-text-default: var(--color-grey-2);
    --color-text-subdued: var(--color-grey-9);
    --color-text-subdued-hover: var(--color-grey-10);
    --color-text-disabled: var(--color-grey-14);
    --color-text-primary: var(--color-blue-10);
    --color-background-bg-default: var(--color-grey-16);
    --color-background-bg-surface: var(--color-grey-15);
    --color-background-bg-surface-default: var(--color-grey-15);
    --color-background-bg-surface-warning: var(--color-orange-13);
    --color-background-bg-surface-active: var(--color-grey-12);
    --color-background-bg-surface-selected: var(--color-grey-14);
    --color-background-bg-surface-subdued: var(--color-grey-14);
    --color-background-bg-surface-subdued-hover: var(--color-grey-11);
    --color-background-bg-surface-subdued-active: var(--color-grey-15);
    --color-background-bg-surface-subdued-selected: var(--color-grey-15);
    --color-border-default: var(--color-grey-14);
    --color-border-hover: var(--color-grey-14);
    --color-border-disabled: var(--color-grey-13);
    --color-border-subdued: var(--color-grey-13);
    --color-background-bg-surface-critical: var(--color-red-14);
    --color-border-warning: var(--color-red-9);
    --color-border-secondary: var(--color-teal-14);
    --color-background-bg-surface-success: var(--color-green-14);
    --color-text-inverse: var(--color-grey-16);
    --color-background-bg-fill: var(--color-grey-16);
    --color-background-bg-fill-primary: var(--color-blue-13);
    --color-background-bg-fill-secondary: var(--color-teal-14);
    --color-background-bg-fill-critical: var(--color-red-13);
    --color-background-bg-fill-disabled: var(--color-grey-13);
    --color-border-primary: var(--color-blue-13);
    --color-text-critical: var(--color-red-9);
    --color-text-secondary: var(--color-teal-8);
    --color-icon-default: var(--color-grey-1);
    --color-icon-subdued: var(--color-grey-9);
    --color-icon-primary: var(--color-blue-10);
    --color-icon-secondary: var(--color-teal-10);
    --color-icon-success: var(--color-green-9);
    --color-icon-warning: var(--color-orange-8);
    --color-icon-critical: var(--color-red-9);
    --color-background-bg-inverse: var(--color-grey-1);
    --color-background-bg-surface-primary: var(--color-blue-14);
    --color-background-bg-surface-secondary: var(--color-teal-14);
    --color-background-bg-surface-primary-hover: var(--color-blue-13);
    --color-icon-disabled: var(--color-grey-12);
    --color-text-warning: var(--color-orange-7);
    --color-background-bg-surface-secondary-hover: var(--color-teal-13);
    --color-background-bg-surface-hover: var(--color-grey-13);
  }
}
