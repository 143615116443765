@import './spacing.variables';

.u-margin-2xsmall {
  margin: var(--space-2xsmall);
}

.u-margin-2xsmall-0 {
  margin-top: var(--space-2xsmall);
  margin-bottom: var(--space-2xsmall);
}

.u-margin-0-2xsmall {
  margin-left: var(--space-2xsmall);
  margin-right: var(--space-2xsmall);
}

.u-marginBottom-0 {
  margin-bottom: var(--space-0);
}

.u-marginBottom-2xsmall {
  margin-bottom: var(--space-2xsmall);
}

.u-marginTop-2xsmall {
  margin-top: var(--space-2xsmall);
}

.u-marginLeft-2xsmall {
  margin-left: var(--space-2xsmall);
}

.u-marginRight-2xsmall {
  margin-right: var(--space-2xsmall);
}

.u-padding-2xsmall {
  padding: var(--space-2xsmall);
}

.u-padding-2xsmall-0 {
  padding-top: var(--space-2xsmall);
  padding-bottom: var(--space-2xsmall);
}

.u-padding-0-2xsmall {
  padding-left: var(--space-2xsmall);
  padding-right: var(--space-2xsmall);
}

.u-paddingBottom-2xsmall {
  padding-bottom: var(--space-2xsmall);
}

.u-paddingTop-2xsmall {
  padding-top: var(--space-2xsmall);
}

.u-paddingLeft-2xsmall {
  padding-left: var(--space-2xsmall);
}

.u-paddingRight-2xsmall {
  padding-right: var(--space-2xsmall);
}

.u-margin-xsmall {
  margin: var(--space-xsmall);
}

.u-margin-xsmall-0 {
  margin-top: var(--space-xsmall);
  margin-bottom: var(--space-xsmall);
}

.u-margin-0-xsmall {
  margin-left: var(--space-xsmall);
  margin-right: var(--space-xsmall);
}

.u-marginBottom-xsmall {
  margin-bottom: var(--space-xsmall);
}

.u-marginTop-xsmall {
  margin-top: var(--space-xsmall);
}

.u-marginLeft-xsmall {
  margin-left: var(--space-xsmall);
}

.u-marginRight-xsmall {
  margin-right: var(--space-xsmall);
}

.u-padding-xsmall {
  padding: var(--space-xsmall);
}

.u-padding-xsmall-0 {
  padding-top: var(--space-xsmall);
  padding-bottom: var(--space-xsmall);
}

.u-padding-0-xsmall {
  padding-left: var(--space-xsmall);
  padding-right: var(--space-xsmall);
}

.u-paddingBottom-xsmall {
  padding-bottom: var(--space-xsmall);
}

.u-paddingTop-xsmall {
  padding-top: var(--space-xsmall);
}

.u-paddingLeft-xsmall {
  padding-left: var(--space-xsmall);
}

.u-paddingRight-xsmall {
  padding-right: var(--space-xsmall);
}

.u-margin-default {
  margin: var(--space-default);
}

.u-margin-default-0 {
  margin-top: var(--space-default);
  margin-bottom: var(--space-default);
}

.u-margin-0-default {
  margin-left: var(--space-default);
  margin-right: var(--space-default);
}

.u-marginBottom-default {
  margin-bottom: var(--space-default);
}

.u-marginTop-default {
  margin-top: var(--space-default);
}

.u-marginLeft-default {
  margin-left: var(--space-default);
}

.u-marginRight-default {
  margin-right: var(--space-default);
}

.u-padding-default {
  padding: var(--space-default);
}

.u-padding-default-0 {
  padding-top: var(--space-default);
  padding-bottom: var(--space-default);
}

.u-padding-0-default {
  padding-left: var(--space-default);
  padding-right: var(--space-default);
}

.u-paddingBottom-default {
  padding-bottom: var(--space-default);
}

.u-paddingTop-default {
  padding-top: var(--space-default);
}

.u-paddingLeft-default {
  padding-left: var(--space-default);
}

.u-paddingRight-default {
  padding-right: var(--space-default);
}

.u-margin-small {
  margin: var(--space-small);
}

.u-margin-small-0 {
  margin-top: var(--space-small);
  margin-bottom: var(--space-small);
}

.u-margin-0-small {
  margin-left: var(--space-small);
  margin-right: var(--space-small);
}

.u-marginBottom-small {
  margin-bottom: var(--space-small);
}

.u-marginTop-small {
  margin-top: var(--space-small);
}

.u-marginLeft-small {
  margin-left: var(--space-small);
}

.u-marginRight-small {
  margin-right: var(--space-small);
}

.u-padding-small {
  padding: var(--space-small);
}

.u-padding-small-0 {
  padding-top: var(--space-small);
  padding-bottom: var(--space-small);
}

.u-padding-0-small {
  padding-left: var(--space-small);
  padding-right: var(--space-small);
}

.u-paddingBottom-small {
  padding-bottom: var(--space-small);
}

.u-paddingTop-small {
  padding-top: var(--space-small);
}

.u-paddingLeft-small {
  padding-left: var(--space-small);
}

.u-paddingRight-small {
  padding-right: var(--space-small);
}

.u-margin-medium {
  margin: var(--space-medium);
}

.u-margin-medium-0 {
  margin-top: var(--space-medium);
  margin-bottom: var(--space-medium);
}

.u-margin-0-medium {
  margin-left: var(--space-medium);
  margin-right: var(--space-medium);
}

.u-marginBottom-medium {
  margin-bottom: var(--space-medium);
}

.u-marginTop-medium {
  margin-top: var(--space-medium);
}

.u-marginLeft-medium {
  margin-left: var(--space-medium);
}

.u-marginRight-medium {
  margin-right: var(--space-medium);
}

.u-padding-medium {
  padding: var(--space-medium);
}

.u-padding-medium-0 {
  padding-top: var(--space-medium);
  padding-bottom: var(--space-medium);
}

.u-padding-0-medium {
  padding-left: var(--space-medium);
  padding-right: var(--space-medium);
}

.u-paddingBottom-medium {
  padding-bottom: var(--space-medium);
}

.u-paddingTop-medium {
  padding-top: var(--space-medium);
}

.u-paddingLeft-medium {
  padding-left: var(--space-medium);
}

.u-paddingRight-medium {
  padding-right: var(--space-medium);
}

$class-prefix: '.u-';
$point-grids: $point-grid-1, $point-grid-2, $point-grid-3, $point-grid-4,
  $point-grid-5;
$spacing-types: 'margin', 'padding';
$spacing-map: (
  top: (
    name: 'Top',
    attribute: 'top',
  ),
  bottom: (
    name: 'Bottom',
    attribute: 'bottom',
  ),
  right: (
    name: 'Right',
    attribute: 'right',
  ),
  left: (
    name: 'Left',
    attribute: 'left',
  ),
);

#{$class-prefix}margin-center {
  margin-left: auto;
  margin-right: auto;
}

@each $item in $point-grids {
  $itemPixel: #{$item}px;

  @each $spacing in $spacing-types {
    #{$class-prefix}#{$spacing}-#{$item} {
      #{$spacing}: $itemPixel;
    }

    #{$class-prefix}#{$spacing}-0-#{$item} {
      #{$spacing}-left: $itemPixel;
      #{$spacing}-right: $itemPixel;
    }

    #{$class-prefix}#{$spacing}-#{$item}-0 {
      #{$spacing}-top: $itemPixel;
      #{$spacing}-bottom: $itemPixel;
    }

    @each $mapLabel, $mapValue in $spacing-map {
      #{$class-prefix}#{$spacing}#{map-get($mapValue, "name")}-#{$item},
      #{$class-prefix}#{$spacing}#{map-get($mapValue, "name")}-#{$item}-override#{$class-prefix}#{$spacing}#{map-get($mapValue, "name")}-#{$item}-override {
        #{$spacing}-#{map-get($mapValue, "attribute")}: $itemPixel;
      }
    }
  }
}
