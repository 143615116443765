html {
  --heading-font-family: 'Poppins', sans-serif;
  --body-font-family: 'Inter', sans-serif;
  --heading-3xl-family: var(--heading-font-family);
  --heading-3xl-size: 36px;
  --heading-3xl-size-rem: 2.25rem;
  --heading-3xl-weight: 700;
  --heading-3xl-style: normal;
  --heading-3xl-line-height: 48px;
  --heading-3xl-line-height-rem: 3rem;
  --heading-2xl-family: var(--heading-font-family);
  --heading-2xl-size: 30px;
  --heading-2xl-size-rem: 1.875rem;
  --heading-2xl-weight: 700;
  --heading-2xl-style: normal;
  --heading-2xl-line-height: 40px;
  --heading-2xl-line-height-rem: 2.5rem;
  --heading-xl-family: var(--heading-font-family);
  --heading-xl-size: 24px;
  --heading-xl-size-rem: 1.5rem;
  --heading-xl-weight: 700;
  --heading-xl-style: normal;
  --heading-xl-line-height: 32px;
  --heading-xl-line-height-rem: 2rem;
  --heading-lg-family: var(--heading-font-family);
  --heading-lg-size: 20px;
  --heading-lg-size-rem: 1.25rem;
  --heading-lg-weight: 700;
  --heading-lg-style: normal;
  --heading-lg-line-height: 24px;
  --heading-lg-line-height-rem: 1.5rem;
  --heading-md-family: var(--heading-font-family);
  --heading-md-size: 14px;
  --heading-md-size-rem: 0.875rem;
  --heading-md-weight: 600;
  --heading-md-style: normal;
  --heading-md-line-height: 20px;
  --heading-md-line-height-rem: 1.25rem;
  --heading-sm-family: var(--heading-font-family);
  --heading-sm-size: 13px;
  --heading-sm-size-rem: 0.8125rem;
  --heading-sm-weight: 600;
  --heading-sm-style: normal;
  --heading-sm-line-height: 20px;
  --heading-sm-line-height-rem: 1.25rem;
  --heading-xs-family: var(--heading-font-family);
  --heading-xs-size: 12px;
  --heading-xs-size-rem: 0.75rem;
  --heading-xs-weight: 600;
  --heading-xs-style: normal;
  --heading-xs-line-height: 16px;
  --heading-xs-line-height-rem: 1rem;
  --body-lg-default-family: 'Inter';
  --body-lg-default-size: 14px;
  --body-lg-default-size-rem: 0.875rem;
  --body-lg-default-weight: 450;
  --body-lg-default-style: normal;
  --body-lg-default-line-height: 20px;
  --body-lg-default-line-height-rem: 1.25rem;
  --body-md-default-family: 'Inter';
  --body-md-default-size: 13px;
  --body-md-default-size-rem: 0.8125rem;
  --body-md-default-weight: 450;
  --body-md-default-style: normal;
  --body-md-default-line-height: 20px;
  --body-md-default-line-height-rem: 1.25rem;
  --body-md-medium-family: 'Inter';
  --body-md-medium-size: 13px;
  --body-md-medium-size-rem: 0.8125rem;
  --body-md-medium-weight: 700;
  --body-md-medium-style: normal;
  --body-md-medium-line-height: 20px;
  --body-md-medium-line-height-rem: 1.25rem;
  --body-md-semibold-family: 'Inter';
  --body-md-semibold-size: 13px;
  --body-md-semibold-size-rem: 0.8125rem;
  --body-md-semibold-weight: 700;
  --body-md-semibold-style: normal;
  --body-md-semibold-line-height: 20px;
  --body-md-semibold-line-height-rem: 1.25rem;
  --body-md-numeric-family: 'Inter';
  --body-md-numeric-size: 13px;
  --body-md-numeric-size-rem: 0.8125rem;
  --body-md-numeric-weight: 700;
  --body-md-numeric-style: normal;
  --body-md-numeric-line-height: 20px;
  --body-md-numeric-line-height-rem: 1.25rem;
  --body-sm-default-family: 'Inter';
  --body-sm-default-size: 12px;
  --body-sm-default-size-rem: 0.75rem;
  --body-sm-default-weight: 450;
  --body-sm-default-style: normal;
  --body-sm-default-line-height: 16px;
  --body-sm-default-line-height-rem: 1rem;
  --body-xs-default-family: 'Inter';
  --body-xs-default-size: 11px;
  --body-xs-default-size-rem: 0.6875rem;
  --body-xs-default-weight: 450;
  --body-xs-default-style: normal;
  --body-xs-default-line-height: 12px;
  --body-xs-default-line-height-rem: 0.75rem;
}
