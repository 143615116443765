.u-borderRadius-base {
  border-radius: var(--border-radius-base);
}

.u-borderRadius-nested {
  border-radius: var(--border-radius-nested);
}

.u-borderRadius-card {
  border-radius: var(--border-radius-card);
}

.u-borderRadius-inner-card {
  border-radius: var(--border-radius-inner-card);
}

.u-borderRadius-button {
  border-radius: var(--border-radius-button);
}

.u-borderRadius-menu {
  border-radius: var(--border-radius-menu);
}
