@import './app.colors';
@import './app.z-indeces';
@import '../../app/stylesheets/utils/variables';

nav.c-subheader {
  display: block;
  z-index: $z-index-5;

  @media (min-width: $screen-sm) {
    top: $header-height;
    left: 0;
    right: 0;
    position: fixed;
  }
}

.c-subheaderPlaceholder {
  @media (min-width: $screen-sm) {
    height: 50px;
  }
}
