@use '@angular/material' as mat;

@import './colors.variables';
@import './typography.mixins';
@import 'material-icons';

@include mat.all-component-typographies();
@include mat.core();

$ttc-teal: (
  50: #e2eff0,
  100: #b6d6d9,
  200: #86bbc0,
  300: #56a0a6,
  400: #318b93,
  500: $ttc-teal-700,
  600: #0b6f78,
  700: #09646d,
  800: #075a63,
  900: #034750,
  A100: #83eeff,
  A200: #50e6ff,
  A400: #1ddfff,
  A700: #03dcff,
  contrast: (
    50: $ttc-white,
    100: $ttc-white,
    200: $ttc-white,
    300: $ttc-white,
    400: $ttc-white,
    500: $ttc-white,
    600: $ttc-white,
    700: $ttc-white,
    800: $ttc-white,
    900: $ttc-white,
    A100: $ttc-white,
    A200: $ttc-white,
    A400: $ttc-white,
    A700: $ttc-white,
  ),
);

$ttc-blue: (
  50: #e4ebf6,
  100: #bacce8,
  200: #8dabd9,
  300: #5f89c9,
  400: #3c6fbe,
  500: $ttc-blue,
  600: #174fab,
  700: #1345a2,
  800: #0f3c99,
  900: #082b8a,
  A100: #b8c7ff,
  A200: #85a0ff,
  A400: #5178ff,
  A700: #3864ff,
  contrast: (
    50: $ttc-black,
    100: $ttc-black,
    200: $ttc-black,
    300: $ttc-white,
    400: $ttc-white,
    500: $ttc-white,
    600: $ttc-white,
    700: $ttc-white,
    800: $ttc-white,
    900: $ttc-white,
    A100: $ttc-white,
    A200: $ttc-white,
    A400: $ttc-white,
    A700: $ttc-white,
  ),
);

$ttc-red: (
  50: #fee9e6,
  100: #fdc7c0,
  200: #fca297,
  300: #fa7d6d,
  400: #f9614d,
  500: $ttc-orange,
  600: #f73e29,
  700: #f63623,
  800: #f52e1d,
  900: #f31f12,
  A100: $ttc-white,
  A200: #ffeeed,
  A400: #ffbdba,
  A700: #ffa5a1,
  contrast: (
    50: $ttc-black,
    100: $ttc-black,
    200: $ttc-black,
    300: $ttc-white,
    400: $ttc-white,
    500: $ttc-white,
    600: $ttc-white,
    700: $ttc-white,
    800: $ttc-white,
    900: $ttc-white,
    A100: $ttc-black,
    A200: $ttc-white,
    A400: $ttc-white,
    A700: $ttc-white,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$app-primary: mat.define-palette($ttc-blue);
$app-accent: mat.define-palette($ttc-teal, 500, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette($ttc-red);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

.mat-snackbar-light-theme {
  $app-accent-light: mat.define-palette($ttc-blue, 200);
  $app-theme-light: mat.define-light-theme(
    (
      color: (
        primary: $app-primary,
        accent: $app-accent-light,
        warn: $app-warn,
      ),
    )
  );
  @include mat.snack-bar-color($app-theme-light);
  @include mat.button-color($app-theme-light);
}

a.mat-mdc-tab-link {
  min-width: auto;
}

.mat-drawer-container {
  background-color: rgba(0, 0, 0, 0.2);
}

mat-form-field.mat-form-field-width-100 {
  display: block;
  width: 100%;
}

.mat-mdc-checkbox.mat-checkbox--longText {
  label {
    word-break: break-word;
  }
}

.mat-mdc-slide-toggle.mat-slide-toggle--longText {
  height: 100%;
  white-space: normal;
  label {
    white-space: normal;
  }
}

.mat-progress-spinner--white {
  &.mat-mdc-progress-spinner {
    circle {
      stroke: $ttc-white;
    }
  }
}

.mat-progress-spinner--grey100 {
  &.mat-mdc-progress-spinner {
    circle {
      stroke: $ttc-grey-100;
    }
  }
}

.mat-elevation-z2--hover {
  transition: box-shadow 300ms ease-out;

  &:hover {
    @include mat.elevation(2);
  }
}

.mat-elevation-z4--hover {
  transition: box-shadow 300ms ease-out;

  &:hover {
    @include mat.elevation(4);
  }
}

$ttc-typography-config: mat.define-typography-config(
  $font-family: 'Inter, "sans-serif"',
  $subtitle-1: mat.define-typography-level(15px, 130%, 400),
  $subtitle-2: mat.define-typography-level(13px, 150%, 500),
  $body-1: mat.define-typography-level(14px, 150%, 400),
  $body-2: mat.define-typography-level(13px, 150%, 400),
  $button: mat.define-typography-level(13px, 150%, 500),
  $caption: mat.define-typography-level(12px, 140%, 400),
  $overline: mat.define-typography-level(10px, 150%, 400),
);

@include mat.all-component-typographies($ttc-typography-config);
@include mat.core();

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: 'Poppins', 'sans-serif';
  --mdc-dialog-subhead-size: 18px;
  --mdc-dialog-supporting-text-size: 13px;
  --mdc-dialog-supporting-text-line-height: 150%;
}

.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  font-size: 13px;
}

.mdc-button {
  border-radius: var(--border-radius-button) !important;
}
