$ttc-teal-700: rgb(0, 105, 92);
$ttc-teal: rgb(30, 188, 160);
$ttc-teal-100: rgb(209, 234, 230);
$ttc-teal-50: rgb(229, 242, 240);

$ttc-orange-900: rgb(189, 3, 19);
$ttc-orange: rgb(248, 69, 46);
$ttc-orange-300: rgb(249, 121, 84);

$ttc-blue-900: rgb(2, 48, 130);
$ttc-blue: rgb(38, 55, 222);
$ttc-blue-200: rgb(94, 129, 229);

$ttc-black: rgb(25, 25, 25);
$ttc-grey: rgb(65, 66, 71);
$ttc-grey-300: rgb(113, 113, 113);
$ttc-grey-100: rgb(230, 230, 230);
$ttc-grey-50: rgb(236, 240, 241);

$ttc-white: rgba(255, 255, 255, 1);

$ttc-yellow: rgba(146, 148, 60, 1);
