@import 'colors.variables';

a {
  color: inherit;
  outline: 0;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: $ttc-blue;
  text-decoration: none;
}

a:focus,
a:visited {
  text-decoration: none;
  outline: 0;
}

a.color-text-inverse:hover {
  color: var(--color-text-subdued-hover);
}
