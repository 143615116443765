@import 'colors.variables';

.color-primary {
  color: var(--color-text-primary);
}

.color-subdued {
  color: var(--color-text-subdued);
}

.color-disabled {
  color: var(--color-text-disabled);
}

.color-inverse {
  color: var(--color-text-inverse);
}

.color-critical {
  color: var(--color-text-critical);
}

.color-secondary {
  color: var(--color-text-secondary);
}

.color-warning {
  color: var(--color-text-warning);
}

.color-text-default {
  color: var(--color-text-default);
}

.color-text-primary {
  color: var(--color-text-primary);
}

.color-text-subdued {
  color: var(--color-text-subdued);
}

.color-text-subdued-hover:hover {
  color: var(--color-text-subdued-hover) !important;
}

.color-text-disabled {
  color: var(--color-text-disabled);
}

.color-text-inverse {
  color: var(--color-text-inverse);
}

.color-text-critical {
  color: var(--color-text-critical);
}

.color-text-secondary {
  color: var(--color-text-secondary);
}

.color-text-warning {
  color: var(--color-text-warning);
}

.color-icon-default {
  color: var(--color-icon-icon);
}

.color-icon-subdued {
  color: var(--color-icon-subdued);
}

.color-icon-primary {
  color: var(--color-icon-primary);
}

.color-icon-secondary {
  color: var(--color-icon-secondary);
}

.color-icon-success {
  color: var(--color-icon-success);
}

.color-icon-warning {
  color: var(--color-icon-warning);
}

.color-icon-critical {
  color: var(--color-icon-critical);
}

.color-icon-disabled {
  color: var(--color-icon-disabled);
}

.background-color-bg {
  background-color: var(--color-background-bg);
}

.background-color-surface {
  background-color: var(--color-background-bg-surface);
}

.background-color-surface-warning {
  background-color: var(--color-background-bg-surface-warning);
}

.background-color-surface-critical {
  background-color: var(--color-background-bg-surface-critical);
}

.background-color-surface-active {
  background-color: var(--color-background-bg-surface-active);
}

.background-color-surface-hover:hover {
  background-color: var(--color-background-bg-surface-hover);
}

.background-color-surface-selected {
  background-color: var(--color-background-bg-surface-selected);
}

.background-color-subdued {
  background-color: var(--color-background-bg-surface-subdued);
}

.background-color-subdued-hover:hover {
  background-color: var(--color-background-bg-surface-subdued-hover);
}

.background-color-subdued-active {
  background-color: var(--color-background-bg-surface-subdued-active);
}

.background-color-selected {
  background-color: var(--color-background-bg-surface-selected);
}

.background-color-inverse {
  background-color: var(--color-background-bg-inverse);
}

.background-color-primary {
  background-color: var(--color-background-bg-surface-primary);
}

.background-color-primary-hover:hover {
  background-color: var(--color-background-bg-surface-primary-hover);
}

.background-color-secondary {
  background-color: var(--color-background-bg-surface-secondary);
}

.background-color-secondary-hover:hover {
  background-color: var(--color-background-bg-surface-secondary-hover);
}

.background-color-success {
  background-color: var(--color-background-bg-surface-success);
}

.border-color-default {
  border-color: var(--color-border-default);
}

.border-color-hover {
  border-color: var(--color-border-hover);
}

.border-color-disabled {
  border-color: var(--color-border-disabled);
}

.border-color-subdued {
  border-color: var(--color-border-subdued);
}

.border-color-warning {
  border-color: var(--color-border-warning);
}

.border-color-secondary {
  border-color: var(--color-border-secondary);
}

.border-color-primary {
  border-color: var(--color-border-primary);
}

.border-color-primary--hover:hover {
  border-color: var(--color-border-primary);
}

.background-color-icon-default {
  background-color: var(--color-icon-default);
}

.background-color-icon-subdued {
  background-color: var(--color-icon-subdued);
}

.background-color-icon-primary {
  background-color: var(--color-icon-primary);
}

.background-color-icon-secondary {
  background-color: var(--color-icon-secondary);
}

.background-color-icon-success {
  background-color: var(--color-icon-success);
}

.background-color-icon-warning {
  background-color: var(--color-icon-warning);
}

.background-color-icon-critical {
  background-color: var(--color-icon-critical);
}

.background-color-icon-disabled {
  background-color: var(--color-icon-disabled);
}

$colors: (
  teal-700: (
    color: $ttc-teal-700,
  ),
  teal: (
    color: $ttc-teal,
  ),
  teal-100: (
    color: $ttc-teal-100,
  ),
  teal-50: (
    color: $ttc-teal-50,
  ),
  orange-900: (
    color: $ttc-orange-900,
  ),
  orange: (
    color: $ttc-orange,
  ),
  orange-300: (
    color: $ttc-orange-300,
  ),
  blue-900: (
    color: $ttc-blue-900,
  ),
  blue: (
    color: $ttc-blue,
  ),
  blue-200: (
    color: $ttc-blue-200,
  ),
  black: (
    color: $ttc-black,
  ),
  grey: (
    color: $ttc-grey,
  ),
  grey-300: (
    color: $ttc-grey-300,
  ),
  grey-100: (
    color: $ttc-grey-100,
  ),
  grey-50: (
    color: $ttc-grey-50,
  ),
  white: (
    color: $ttc-white,
  ),
  yellow: (
    color: $ttc-yellow,
  ),
);

$class-color-prefix: '.color-';
$class-bg-color-prefix: '.bg-color-';
$class-hover--suffix: '--hover';

@each $mapLabel, $mapValue in $colors {
  #{$class-color-prefix}#{'' + $mapLabel} {
    color: map_get($mapValue, 'color');
  }

  #{$class-color-prefix}#{'' + $mapLabel}:hover {
    color: map_get($mapValue, 'color');
  }

  #{$class-bg-color-prefix}#{'' + $mapLabel} {
    background-color: map_get($mapValue, 'color');
  }

  #{$class-bg-color-prefix}#{'' + $mapLabel}#{'--10'} {
    background-color: rgba(map_get($mapValue, 'color'), 0.1);
  }

  #{$class-bg-color-prefix}#{'' + $mapLabel}#{'--20'} {
    background-color: rgba(map_get($mapValue, 'color'), 0.2);
  }

  #{$class-bg-color-prefix}#{'' + $mapLabel}#{'--30'} {
    background-color: rgba(map_get($mapValue, 'color'), 0.3);
  }

  #{$class-bg-color-prefix}#{'' + $mapLabel}#{'--4'} {
    background-color: rgba(map_get($mapValue, 'color'), 0.04);
  }

  #{$class-bg-color-prefix}#{''
    + $mapLabel}#{$class-hover--suffix}#{'-4'}:hover {
    background-color: rgba(map_get($mapValue, 'color'), 0.04) !important;
  }
}
