@import './colors.variables';

@mixin m-color-teal-700() {
  color: $ttc-teal-700;
}

@mixin m-color-teal() {
  color: $ttc-teal;
}

@mixin m-color-teal-100() {
  color: $ttc-teal-100;
}

@mixin m-backgroundColor-teal-700() {
  background-color: $ttc-teal-700;
}

@mixin m-backgroundColor-teal() {
  background-color: $ttc-teal;
}

@mixin m-backgroundColor-teal-100() {
  background-color: $ttc-teal-100;
}

@mixin m-backgroundColor-teal-50() {
  background-color: $ttc-teal-50;
}

@mixin m-color-teal-50() {
  color: $ttc-teal-50;
}

@mixin m-color-orange-900 {
  color: $ttc-orange-900;
}

@mixin m-color-orange {
  color: $ttc-orange;
}

@mixin m-color-orange-300 {
  color: $ttc-orange-300;
}

@mixin m-backgroundColor-orange-900 {
  background-color: $ttc-orange-900;
}

@mixin m-backgroundColor-orange {
  background-color: $ttc-orange;
}

@mixin m-backgroundColor-orange-300 {
  background-color: $ttc-orange-300;
}

@mixin m-color-blue-900 {
  color: $ttc-blue-900;
}

@mixin m-color-blue {
  color: $ttc-blue;
}

@mixin m-color-blue-200 {
  color: $ttc-blue-200;
}

@mixin m-backgroundColor-blue-900 {
  background-color: $ttc-blue-900;
}

@mixin m-backgroundColor-blue {
  background-color: $ttc-blue;
}

@mixin m-backgroundColor-blue-200 {
  background-color: $ttc-blue-200;
}

@mixin m-color-black {
  color: $ttc-black;
}

@mixin m-color-grey {
  color: $ttc-grey;
}

@mixin m-color-grey-300 {
  color: $ttc-grey-300;
}

@mixin m-color-grey-100 {
  color: $ttc-grey-100;
}

@mixin m-color-grey-50 {
  color: $ttc-grey-50;
}

@mixin m-color-white {
  color: $ttc-white;
}

@mixin m-backgroundColor-black {
  background-color: $ttc-black;
}

@mixin m-backgroundColor-grey {
  background-color: $ttc-grey;
}

@mixin m-backgroundColor-grey-300 {
  background-color: $ttc-grey-300;
}

@mixin m-backgroundColor-grey-100 {
  background-color: $ttc-grey-100;
}

@mixin m-backgroundColor-grey-50 {
  background-color: $ttc-grey-50;
}

@mixin m-backgroundColor-white {
  background-color: $ttc-white;
}
