@import 'typography.mixins';

h1 {
  @include mdc-typography-styles-headline1();

  b {
    @include m-text-strong();
  }
}

h2 {
  @include mdc-typography-styles-headline2();

  b {
    @include m-text-strong();
  }
}

h3 {
  @include mdc-typography-styles-headline3();

  b {
    @include m-text-strong();
  }
}

h4,
.text-heading-4 {
  @include mdc-typography-styles-headline4();

  b {
    @include m-text-strong();
  }
}

h5,
.text-heading-5 {
  @include mdc-typography-styles-headline5();

  b {
    @include m-text-strong();
  }
}

h6,
.text-heading-6 {
  @include mdc-typography-styles-headline6();

  b {
    @include m-text-strong();
  }
}

.text-subtitle-1 {
  @include mdc-typography-styles-subtitle1();
}

.text-subtitle-2 {
  @include mdc-typography-styles-subtitle2();
}

.text-body-1 {
  @include mdc-typography-styles-body1();
}

.text-body-2 {
  @include mdc-typography-styles-body2();
}

.text-caption {
  @include mdc-typography-styles-caption();
}

.text-overline {
  @include mdc-typography-styles-overline();
}

b {
  @include m-text-bold();
}

strong {
  @include m-text-strong();
}

em {
  @include m-text-italic();
}

.text-heading-3xl {
  @include ttc-typography-styles-heading-3xl();
}

.text-heading-2xl {
  @include ttc-typography-styles-heading-2xl();
}

.text-heading-xl {
  @include ttc-typography-styles-heading-xl();
}

.text-heading-lg {
  @include ttc-typography-styles-heading-lg();
}

.text-heading-md {
  @include ttc-typography-styles-heading-md();
}

.text-heading-sm {
  @include ttc-typography-styles-heading-sm();
}

.text-heading-xs {
  @include ttc-typography-styles-heading-xs();
}

.text-body-lg {
  @include ttc-typography-styles-body-lg();
}

.text-body-md {
  @include ttc-typography-styles-body-md();
}

.text-body-sm {
  @include ttc-typography-styles-body-sm();
}

.text-body-xs {
  @include ttc-typography-styles-body-xs();
}
