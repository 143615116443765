.c-button {
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  overflow: visible;
  text-align: left;
  padding: 0;
  -webkit-appearance: button;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  .material-icons.md-18,
  .material-icons-outlined.md-18,
  .material-icons-two-tone.md-18,
  .material-symbols-outlined.md-18 {
    vertical-align: -4px;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.38);
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }
}
