@import '../../../src/stylesheets/app.colors';
@import '../../../src/stylesheets/app.spacing.variables';

.mat-mdc-chip {
  margin: 0 #{$point-grid-1}px #{$point-grid-1}px 0 !important;
  font-weight: 400 !important;
}

.mat-mdc-input-element--bg-grey.mat-input-element {
  @include m-backgroundColor-grey-100();
  padding: #{$point-grid-2}px;
}

.mat-expansion-panel-body-margin-0 {
  .mat-expansion-panel-body {
    margin: 0 !important;
  }
}

.mat-radio-button-label-white-space-normal,
.mat-mdc-radio-button.mat-radio-button--longText,
.mat-mdc-checkbox {
  label {
    white-space: normal;
  }
}

.mat-badge-small span.mat-badge-content {
  font-size: 10px;
}

.mat-mdc-chip-list-wrapper .mat-chip--outline.mat-chip.mat-mdc-standard-chip {
  @include mdc-typography-styles-caption();
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
  background-color: $ttc-white;
  color: $ttc-grey;
}
.mat-mdc-chip-list-wrapper
  .mat-chip--outline-warn.mat-chip.mat-mdc-standard-chip {
  @include mdc-typography-styles-caption();
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
  background-color: rgba(249, 121, 84, 0.2);
  border: $ttc-orange;
  color: $ttc-orange;
}

.mat-mdc-chip-list-wrapper .mat-chip.mat-mdc-standard-chip {
  background-color: $ttc-grey-100;
}

button.mat-mdc-outlined-button,
a.mat-stroked-button {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-mdc-dialog-container {
  position: relative;
  .customMatDialogTitle.mdc-dialog__title {
    padding: 16px 24px;
  }
}

textarea.mat-mdc-input-element {
  @include mdc-typography-styles-body2();
}

.mat-tab-group-hide-headers {
  .mat-mdc-tab-header {
    display: none;
  }
}

.mat-mdc-table {
  .mat-mdc-header-cell,
  .mdc-data-table__cell {
    vertical-align: middle;
  }
  &.mat-mdc-table--noWrap {
    th,
    tr {
      white-space: nowrap;
    }
  }
}

div.mat-mdc-tooltip {
  padding-top: 0;
  padding-bottom: 0;
}

.mat-select-multiple-option--hideCheckbox {
  .mat-pseudo-checkbox {
    display: none;
  }
}

button.mat-menu-item,
td.mat-cell,
td.mat-footer-cell,
.mat-expansion-panel-header-title,
.mat-expansion-panel-body,
.mat-expansion-panel-content,
.mat-expansion-panel-header-description,
.mat-content,
.mat-mdc-slide-toggle label,
.mat-mdc-list-base .mat-mdc-list-item .mdc-list-item__primary-text {
  @include mdc-typography-styles-body2();
}

.mat-mdc-form-field-hint {
  font-size: 10.5px;
}

mat-label {
  vertical-align: 1px;
}

.mat-slide-toggle-label--before {
  .mdc-form-field {
    width: 100%;
    > label {
      margin-left: inherit;
      margin-right: auto;
    }
  }
}

.mat-mdc-button-touch-target {
  height: auto !important;
}

.mat-mdc-icon-button {
  .mat-icon {
    vertical-align: 6px;
  }
}
