html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  --size-0: 0px;
  --size-0-rem: 0rem;
  --size-025: 1px;
  --size-025-rem: 0.0625rem;
  --size-050: 2px;
  --size-050-rem: 0.125rem;
  --size-100: 4px;
  --size-100-rem: 0.25rem;
  --size-150: 6px;
  --size-150-rem: 0.375rem;
  --size-200: 8px;
  --size-200-rem: 0.5rem;
  --size-300: 12px;
  --size-300-rem: 0.75rem;
  --size-400: 16px;
  --size-400-rem: 1rem;
  --size-500: 20px;
  --size-500-rem: 1.25rem;
  --size-600: 24px;
  --size-600-rem: 1.5rem;
  --size-800: 32px;
  --size-800-rem: 2rem;
  --size-1000: 40px;
  --size-1000-rem: 2.5rem;
  --size-1200: 48px;
  --size-1200-rem: 3rem;
  --size-1600: 64px;
  --size-1600-rem: 4rem;
  --size-2000: 80px;
  --size-2000-rem: 5rem;
  --size-2400: 96px;
  --size-2400-rem: 6rem;
  --size-2800: 112px;
  --size-2800-rem: 7rem;
  --size-3200: 128px;
  --size-3200-rem: 8rem;
  --size-750: 30px;
  --size-750-rem: 1.875rem;
  --size-550: 22px;
  --size-550-rem: 1.375rem;
  --size-450: 18px;
  --size-450-rem: 1.125rem;
  --size-350: 14px;
  --size-350-rem: 0.875rem;
  --space-0: var(--size-0);
  --space-025: var(--size-025);
  --space-050: var(--size-050);
  --space-100: var(--size-100);
  --space-150: var(--size-150);
  --space-200: var(--size-200);
  --space-300: var(--size-300);
  --space-400: var(--size-400);
  --space-500: var(--size-500);
  --space-600: var(--size-600);
  --space-800: var(--size-800);
  --space-1000: var(--size-1000);
  --space-1200: var(--size-1200);
  --space-1600: var(--size-1600);
  --space-2000: var(--size-2000);
  --space-2400: var(--size-2400);
  --space-2800: var(--size-2800);
  --space-3200: var(--size-3200);
  --border-radius-full: 99999px;
  --border-radius-full-rem: 6249.9375rem;
  --border-radius-0: var(--size-0);
  --border-radius-050: var(--size-050);
  --border-radius-100: var(--size-100);
  --border-radius-150: var(--size-150);
  --border-radius-200: var(--size-200);
  --border-radius-300: var(--size-300);
  --border-radius-400: var(--size-400);
  --border-radius-550: var(--size-550);
  --border-radius-750: var(--size-750);
  --border-width-025: var(--size-025);
  --border-width-050: var(--size-050);
  --border-width-100: var(--size-100);
  --color-grey-16: rgb(26 26 26);
  --color-grey-15: rgb(48 48 48);
  --color-grey-14: rgb(74 74 74);
  --color-grey-13: rgb(97 97 97);
  --color-grey-12: rgb(138 138 138);
  --color-grey-11: rgb(181 181 181);
  --color-grey-10: rgb(204 204 204);
  --color-grey-9: rgb(212 212 212);
  --color-grey-8: rgb(227 227 227);
  --color-grey-7: rgb(235 235 235);
  --color-grey-6: rgb(241 241 241);
  --color-grey-5: rgb(243 243 243);
  --color-grey-4: rgb(243 243 243);
  --color-grey-3: rgb(250 250 250);
  --color-grey-2: rgb(253 253 253);
  --color-grey-1: rgb(255 255 255);
  --color-blue-16: rgb(19 20 38);
  --color-blue-15: rgb(30 34 77);
  --color-blue-14: rgb(36 44 122);
  --color-blue-13: rgb(38 50 171);
  --color-blue-12: rgb(38 55 222);
  --color-blue-11: rgb(72 87 239);
  --color-blue-10: rgb(133 144 254);
  --color-blue-9: rgb(184 190 255);
  --color-blue-8: rgb(209 213 255);
  --color-blue-7: rgb(209 213 255);
  --color-blue-6: rgb(219 223 255);
  --color-blue-5: rgb(230 232 254);
  --color-blue-4: rgb(235 237 255);
  --color-blue-3: rgb(240 241 255);
  --color-blue-2: rgb(245 246 255);
  --color-blue-1: rgb(250 250 255);
  --color-teal-16: rgb(5 10 9);
  --color-teal-15: rgb(19 47 42);
  --color-teal-14: rgb(28 89 78);
  --color-teal-13: rgb(32 136 118);
  --color-teal-12: rgb(30 188 160);
  --color-teal-11: rgb(28 217 183);
  --color-teal-10: rgb(67 229 200);
  --color-teal-9: rgb(133 239 220);
  --color-teal-8: rgb(180 244 232);
  --color-teal-7: rgb(198 246 237);
  --color-teal-6: rgb(211 248 241);
  --color-teal-5: rgb(223 251 246);
  --color-teal-4: rgb(228 251 247);
  --color-teal-3: rgb(237 252 250);
  --color-teal-2: rgb(242 253 251);
  --color-teal-1: rgb(251 254 254);
  --color-orange-16: rgb(37 20 0);
  --color-orange-15: rgb(65 35 0);
  --color-orange-14: rgb(94 50 0);
  --color-orange-13: rgb(124 68 0);
  --color-orange-12: rgb(149 87 0);
  --color-orange-11: rgb(178 101 0);
  --color-orange-10: rgb(229 126 0);
  --color-orange-9: rgb(255 140 0);
  --color-orange-8: rgb(255 199 121);
  --color-orange-7: rgb(255 214 164);
  --color-orange-6: rgb(255 221 182);
  --color-orange-5: rgb(255 228 198);
  --color-orange-4: rgb(255 235 213);
  --color-orange-3: rgb(255 241 227);
  --color-orange-2: rgb(255 247 238);
  --color-orange-1: rgb(255 253 250);
  --color-red-16: rgb(47 10 4);
  --color-red-15: rgb(95 21 7);
  --color-red-14: rgb(142 31 11);
  --color-red-13: rgb(181 38 11);
  --color-red-12: rgb(229 50 0);
  --color-red-11: rgb(239 77 47);
  --color-red-10: rgb(253 129 122);
  --color-red-9: rgb(253 176 172);
  --color-red-8: rgb(254 195 193);
  --color-red-7: rgb(254 211 209);
  --color-red-6: rgb(254 218 217);
  --color-red-5: rgb(254 226 225);
  --color-red-4: rgb(254 233 232);
  --color-red-3: rgb(255 237 236);
  --color-red-2: rgb(255 246 246);
  --color-red-1: rgb(255 251 251);
  --color-green-16: rgb(13 42 9);
  --color-green-15: rgb(14 61 8);
  --color-green-14: rgb(20 81 12);
  --color-green-13: rgb(30 111 19);
  --color-green-12: rgb(52 132 41);
  --color-green-11: rgb(63 160 50);
  --color-green-10: rgb(46 211 96);
  --color-green-9: rgb(50 225 129);
  --color-green-8: rgb(53 238 152);
  --color-green-7: rgb(56 250 163);
  --color-green-6: rgb(99 253 176);
  --color-green-5: rgb(146 254 194);
  --color-green-4: rgb(180 254 210);
  --color-green-3: rgb(205 254 225);
  --color-green-2: rgb(227 255 237);
  --color-green-1: rgb(248 255 251);
  --space-default: var(--size-400);
  --space-2xsmall: var(--size-100);
  --space-xsmall: var(--size-200);
  --space-small: var(--size-300);
  --space-medium: var(--size-600);
  --border-radius-card: var(--border-radius-550);
  --border-radius-button: var(--border-radius-200);
  --border-radius-menu: var(--border-radius-300);
  --border-radius-inner-card: var(--border-radius-300);
  --border-radius-base: var(--border-radius-300);
  --border-radius-nested: var(--border-radius-100);
}
html body[data-theme=light] {
  --color-text-default: var(--color-grey-16);
  --color-text-subdued: var(--color-grey-13);
  --color-text-subdued-hover: var(--color-grey-12);
  --color-text-disabled: var(--color-grey-12);
  --color-text-primary: var(--color-blue-12);
  --color-text-inverse: var(--color-grey-1);
  --color-text-critical: var(--color-red-11);
  --color-text-secondary: var(--color-teal-14);
  --color-text-warning: var(--color-orange-9);
  --color-background-bg-default: var(--color-grey-5);
  --color-background-bg-surface: var(--color-grey-1);
  --color-background-bg-surface-warning: var(--color-orange-4);
  --color-background-bg-surface-active: var(--color-grey-6);
  --color-background-bg-surface-hover: var(--color-grey-5);
  --color-background-bg-surface-selected: var(--color-grey-3);
  --color-background-bg-surface-subdued: var(--color-grey-6);
  --color-background-bg-surface-subdued-hover: var(--color-grey-4);
  --color-background-bg-surface-subdued-active: var(--color-grey-7);
  --color-background-bg-surface-subdued-selected: var(--color-grey-7);
  --color-background-bg-inverse: var(--color-grey-16);
  --color-background-bg-surface-primary: var(--color-blue-5);
  --color-background-bg-surface-primary-hover: var(--color-blue-2);
  --color-background-bg-surface-secondary: var(--color-teal-5);
  --color-background-bg-surface-secondary-hover: var(--color-teal-2);
  --color-background-bg-surface-critical: var(--color-red-4);
  --color-background-bg-surface-success: var(--color-green-2);
  --color-background-bg-fill: var(--color-grey-1);
  --color-background-bg-fill-primary: var(--color-blue-12);
  --color-background-bg-fill-secondary: var(--color-teal-14);
  --color-background-bg-fill-critical: var(--color-red-11);
  --color-background-bg-fill-disabled: var(--color-grey-8);
  --color-border-default: var(--color-grey-8);
  --color-border-hover: var(--color-grey-10);
  --color-border-disabled: var(--color-grey-7);
  --color-border-subdued: var(--color-grey-7);
  --color-border-warning: var(--color-red-9);
  --color-border-secondary: var(--color-teal-13);
  --color-border-primary: var(--color-blue-12);
  --color-icon-default: var(--color-grey-16);
  --color-icon-subdued: var(--color-grey-13);
  --color-icon-primary: var(--color-blue-12);
  --color-icon-secondary: var(--color-teal-13);
  --color-icon-success: var(--color-green-11);
  --color-icon-warning: var(--color-orange-10);
  --color-icon-critical: var(--color-red-12);
  --color-icon-disabled: var(--color-grey-12);
}
html body[data-theme=dark] {
  --color-text-default: var(--color-grey-2);
  --color-text-subdued: var(--color-grey-9);
  --color-text-subdued-hover: var(--color-grey-10);
  --color-text-disabled: var(--color-grey-14);
  --color-text-primary: var(--color-blue-10);
  --color-background-bg-default: var(--color-grey-16);
  --color-background-bg-surface: var(--color-grey-15);
  --color-background-bg-surface-default: var(--color-grey-15);
  --color-background-bg-surface-warning: var(--color-orange-13);
  --color-background-bg-surface-active: var(--color-grey-12);
  --color-background-bg-surface-selected: var(--color-grey-14);
  --color-background-bg-surface-subdued: var(--color-grey-14);
  --color-background-bg-surface-subdued-hover: var(--color-grey-11);
  --color-background-bg-surface-subdued-active: var(--color-grey-15);
  --color-background-bg-surface-subdued-selected: var(--color-grey-15);
  --color-border-default: var(--color-grey-14);
  --color-border-hover: var(--color-grey-14);
  --color-border-disabled: var(--color-grey-13);
  --color-border-subdued: var(--color-grey-13);
  --color-background-bg-surface-critical: var(--color-red-14);
  --color-border-warning: var(--color-red-9);
  --color-border-secondary: var(--color-teal-14);
  --color-background-bg-surface-success: var(--color-green-14);
  --color-text-inverse: var(--color-grey-16);
  --color-background-bg-fill: var(--color-grey-16);
  --color-background-bg-fill-primary: var(--color-blue-13);
  --color-background-bg-fill-secondary: var(--color-teal-14);
  --color-background-bg-fill-critical: var(--color-red-13);
  --color-background-bg-fill-disabled: var(--color-grey-13);
  --color-border-primary: var(--color-blue-13);
  --color-text-critical: var(--color-red-9);
  --color-text-secondary: var(--color-teal-8);
  --color-icon-default: var(--color-grey-1);
  --color-icon-subdued: var(--color-grey-9);
  --color-icon-primary: var(--color-blue-10);
  --color-icon-secondary: var(--color-teal-10);
  --color-icon-success: var(--color-green-9);
  --color-icon-warning: var(--color-orange-8);
  --color-icon-critical: var(--color-red-9);
  --color-background-bg-inverse: var(--color-grey-1);
  --color-background-bg-surface-primary: var(--color-blue-14);
  --color-background-bg-surface-secondary: var(--color-teal-14);
  --color-background-bg-surface-primary-hover: var(--color-blue-13);
  --color-icon-disabled: var(--color-grey-12);
  --color-text-warning: var(--color-orange-7);
  --color-background-bg-surface-secondary-hover: var(--color-teal-13);
  --color-background-bg-surface-hover: var(--color-grey-13);
}

html {
  --heading-font-family: "Poppins", sans-serif;
  --body-font-family: "Inter", sans-serif;
  --heading-3xl-family: var(--heading-font-family);
  --heading-3xl-size: 36px;
  --heading-3xl-size-rem: 2.25rem;
  --heading-3xl-weight: 700;
  --heading-3xl-style: normal;
  --heading-3xl-line-height: 48px;
  --heading-3xl-line-height-rem: 3rem;
  --heading-2xl-family: var(--heading-font-family);
  --heading-2xl-size: 30px;
  --heading-2xl-size-rem: 1.875rem;
  --heading-2xl-weight: 700;
  --heading-2xl-style: normal;
  --heading-2xl-line-height: 40px;
  --heading-2xl-line-height-rem: 2.5rem;
  --heading-xl-family: var(--heading-font-family);
  --heading-xl-size: 24px;
  --heading-xl-size-rem: 1.5rem;
  --heading-xl-weight: 700;
  --heading-xl-style: normal;
  --heading-xl-line-height: 32px;
  --heading-xl-line-height-rem: 2rem;
  --heading-lg-family: var(--heading-font-family);
  --heading-lg-size: 20px;
  --heading-lg-size-rem: 1.25rem;
  --heading-lg-weight: 700;
  --heading-lg-style: normal;
  --heading-lg-line-height: 24px;
  --heading-lg-line-height-rem: 1.5rem;
  --heading-md-family: var(--heading-font-family);
  --heading-md-size: 14px;
  --heading-md-size-rem: 0.875rem;
  --heading-md-weight: 600;
  --heading-md-style: normal;
  --heading-md-line-height: 20px;
  --heading-md-line-height-rem: 1.25rem;
  --heading-sm-family: var(--heading-font-family);
  --heading-sm-size: 13px;
  --heading-sm-size-rem: 0.8125rem;
  --heading-sm-weight: 600;
  --heading-sm-style: normal;
  --heading-sm-line-height: 20px;
  --heading-sm-line-height-rem: 1.25rem;
  --heading-xs-family: var(--heading-font-family);
  --heading-xs-size: 12px;
  --heading-xs-size-rem: 0.75rem;
  --heading-xs-weight: 600;
  --heading-xs-style: normal;
  --heading-xs-line-height: 16px;
  --heading-xs-line-height-rem: 1rem;
  --body-lg-default-family: "Inter";
  --body-lg-default-size: 14px;
  --body-lg-default-size-rem: 0.875rem;
  --body-lg-default-weight: 450;
  --body-lg-default-style: normal;
  --body-lg-default-line-height: 20px;
  --body-lg-default-line-height-rem: 1.25rem;
  --body-md-default-family: "Inter";
  --body-md-default-size: 13px;
  --body-md-default-size-rem: 0.8125rem;
  --body-md-default-weight: 450;
  --body-md-default-style: normal;
  --body-md-default-line-height: 20px;
  --body-md-default-line-height-rem: 1.25rem;
  --body-md-medium-family: "Inter";
  --body-md-medium-size: 13px;
  --body-md-medium-size-rem: 0.8125rem;
  --body-md-medium-weight: 700;
  --body-md-medium-style: normal;
  --body-md-medium-line-height: 20px;
  --body-md-medium-line-height-rem: 1.25rem;
  --body-md-semibold-family: "Inter";
  --body-md-semibold-size: 13px;
  --body-md-semibold-size-rem: 0.8125rem;
  --body-md-semibold-weight: 700;
  --body-md-semibold-style: normal;
  --body-md-semibold-line-height: 20px;
  --body-md-semibold-line-height-rem: 1.25rem;
  --body-md-numeric-family: "Inter";
  --body-md-numeric-size: 13px;
  --body-md-numeric-size-rem: 0.8125rem;
  --body-md-numeric-weight: 700;
  --body-md-numeric-style: normal;
  --body-md-numeric-line-height: 20px;
  --body-md-numeric-line-height-rem: 1.25rem;
  --body-sm-default-family: "Inter";
  --body-sm-default-size: 12px;
  --body-sm-default-size-rem: 0.75rem;
  --body-sm-default-weight: 450;
  --body-sm-default-style: normal;
  --body-sm-default-line-height: 16px;
  --body-sm-default-line-height-rem: 1rem;
  --body-xs-default-family: "Inter";
  --body-xs-default-size: 11px;
  --body-xs-default-size-rem: 0.6875rem;
  --body-xs-default-weight: 450;
  --body-xs-default-style: normal;
  --body-xs-default-line-height: 12px;
  --body-xs-default-line-height-rem: 0.75rem;
}

.u-borderRadius-base {
  border-radius: var(--border-radius-base);
}

.u-borderRadius-nested {
  border-radius: var(--border-radius-nested);
}

.u-borderRadius-card {
  border-radius: var(--border-radius-card);
}

.u-borderRadius-inner-card {
  border-radius: var(--border-radius-inner-card);
}

.u-borderRadius-button {
  border-radius: var(--border-radius-button);
}

.u-borderRadius-menu {
  border-radius: var(--border-radius-menu);
}

.c-button {
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  overflow: visible;
  text-align: left;
  padding: 0;
  -webkit-appearance: button;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.c-button .material-icons.md-18,
.c-button .material-icons-outlined.md-18,
.c-button .material-icons-two-tone.md-18,
.c-button .material-symbols-outlined.md-18 {
  vertical-align: -4px;
}
.c-button:disabled {
  color: rgba(0, 0, 0, 0.38);
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.color-primary {
  color: var(--color-text-primary);
}

.color-subdued {
  color: var(--color-text-subdued);
}

.color-disabled {
  color: var(--color-text-disabled);
}

.color-inverse {
  color: var(--color-text-inverse);
}

.color-critical {
  color: var(--color-text-critical);
}

.color-secondary {
  color: var(--color-text-secondary);
}

.color-warning {
  color: var(--color-text-warning);
}

.color-text-default {
  color: var(--color-text-default);
}

.color-text-primary {
  color: var(--color-text-primary);
}

.color-text-subdued {
  color: var(--color-text-subdued);
}

.color-text-subdued-hover:hover {
  color: var(--color-text-subdued-hover) !important;
}

.color-text-disabled {
  color: var(--color-text-disabled);
}

.color-text-inverse {
  color: var(--color-text-inverse);
}

.color-text-critical {
  color: var(--color-text-critical);
}

.color-text-secondary {
  color: var(--color-text-secondary);
}

.color-text-warning {
  color: var(--color-text-warning);
}

.color-icon-default {
  color: var(--color-icon-icon);
}

.color-icon-subdued {
  color: var(--color-icon-subdued);
}

.color-icon-primary {
  color: var(--color-icon-primary);
}

.color-icon-secondary {
  color: var(--color-icon-secondary);
}

.color-icon-success {
  color: var(--color-icon-success);
}

.color-icon-warning {
  color: var(--color-icon-warning);
}

.color-icon-critical {
  color: var(--color-icon-critical);
}

.color-icon-disabled {
  color: var(--color-icon-disabled);
}

.background-color-bg {
  background-color: var(--color-background-bg);
}

.background-color-surface {
  background-color: var(--color-background-bg-surface);
}

.background-color-surface-warning {
  background-color: var(--color-background-bg-surface-warning);
}

.background-color-surface-critical {
  background-color: var(--color-background-bg-surface-critical);
}

.background-color-surface-active {
  background-color: var(--color-background-bg-surface-active);
}

.background-color-surface-hover:hover {
  background-color: var(--color-background-bg-surface-hover);
}

.background-color-surface-selected {
  background-color: var(--color-background-bg-surface-selected);
}

.background-color-subdued {
  background-color: var(--color-background-bg-surface-subdued);
}

.background-color-subdued-hover:hover {
  background-color: var(--color-background-bg-surface-subdued-hover);
}

.background-color-subdued-active {
  background-color: var(--color-background-bg-surface-subdued-active);
}

.background-color-selected {
  background-color: var(--color-background-bg-surface-selected);
}

.background-color-inverse {
  background-color: var(--color-background-bg-inverse);
}

.background-color-primary {
  background-color: var(--color-background-bg-surface-primary);
}

.background-color-primary-hover:hover {
  background-color: var(--color-background-bg-surface-primary-hover);
}

.background-color-secondary {
  background-color: var(--color-background-bg-surface-secondary);
}

.background-color-secondary-hover:hover {
  background-color: var(--color-background-bg-surface-secondary-hover);
}

.background-color-success {
  background-color: var(--color-background-bg-surface-success);
}

.border-color-default {
  border-color: var(--color-border-default);
}

.border-color-hover {
  border-color: var(--color-border-hover);
}

.border-color-disabled {
  border-color: var(--color-border-disabled);
}

.border-color-subdued {
  border-color: var(--color-border-subdued);
}

.border-color-warning {
  border-color: var(--color-border-warning);
}

.border-color-secondary {
  border-color: var(--color-border-secondary);
}

.border-color-primary {
  border-color: var(--color-border-primary);
}

.border-color-primary--hover:hover {
  border-color: var(--color-border-primary);
}

.background-color-icon-default {
  background-color: var(--color-icon-default);
}

.background-color-icon-subdued {
  background-color: var(--color-icon-subdued);
}

.background-color-icon-primary {
  background-color: var(--color-icon-primary);
}

.background-color-icon-secondary {
  background-color: var(--color-icon-secondary);
}

.background-color-icon-success {
  background-color: var(--color-icon-success);
}

.background-color-icon-warning {
  background-color: var(--color-icon-warning);
}

.background-color-icon-critical {
  background-color: var(--color-icon-critical);
}

.background-color-icon-disabled {
  background-color: var(--color-icon-disabled);
}

.color-teal-700 {
  color: rgb(0, 105, 92);
}

.color-teal-700:hover {
  color: rgb(0, 105, 92);
}

.bg-color-teal-700 {
  background-color: rgb(0, 105, 92);
}

.bg-color-teal-700--10 {
  background-color: rgba(0, 105, 92, 0.1);
}

.bg-color-teal-700--20 {
  background-color: rgba(0, 105, 92, 0.2);
}

.bg-color-teal-700--30 {
  background-color: rgba(0, 105, 92, 0.3);
}

.bg-color-teal-700--4 {
  background-color: rgba(0, 105, 92, 0.04);
}

.bg-color-teal-700--hover-4:hover {
  background-color: rgba(0, 105, 92, 0.04) !important;
}

.color-teal {
  color: rgb(30, 188, 160);
}

.color-teal:hover {
  color: rgb(30, 188, 160);
}

.bg-color-teal {
  background-color: rgb(30, 188, 160);
}

.bg-color-teal--10 {
  background-color: rgba(30, 188, 160, 0.1);
}

.bg-color-teal--20 {
  background-color: rgba(30, 188, 160, 0.2);
}

.bg-color-teal--30 {
  background-color: rgba(30, 188, 160, 0.3);
}

.bg-color-teal--4 {
  background-color: rgba(30, 188, 160, 0.04);
}

.bg-color-teal--hover-4:hover {
  background-color: rgba(30, 188, 160, 0.04) !important;
}

.color-teal-100 {
  color: rgb(209, 234, 230);
}

.color-teal-100:hover {
  color: rgb(209, 234, 230);
}

.bg-color-teal-100 {
  background-color: rgb(209, 234, 230);
}

.bg-color-teal-100--10 {
  background-color: rgba(209, 234, 230, 0.1);
}

.bg-color-teal-100--20 {
  background-color: rgba(209, 234, 230, 0.2);
}

.bg-color-teal-100--30 {
  background-color: rgba(209, 234, 230, 0.3);
}

.bg-color-teal-100--4 {
  background-color: rgba(209, 234, 230, 0.04);
}

.bg-color-teal-100--hover-4:hover {
  background-color: rgba(209, 234, 230, 0.04) !important;
}

.color-teal-50 {
  color: rgb(229, 242, 240);
}

.color-teal-50:hover {
  color: rgb(229, 242, 240);
}

.bg-color-teal-50 {
  background-color: rgb(229, 242, 240);
}

.bg-color-teal-50--10 {
  background-color: rgba(229, 242, 240, 0.1);
}

.bg-color-teal-50--20 {
  background-color: rgba(229, 242, 240, 0.2);
}

.bg-color-teal-50--30 {
  background-color: rgba(229, 242, 240, 0.3);
}

.bg-color-teal-50--4 {
  background-color: rgba(229, 242, 240, 0.04);
}

.bg-color-teal-50--hover-4:hover {
  background-color: rgba(229, 242, 240, 0.04) !important;
}

.color-orange-900 {
  color: rgb(189, 3, 19);
}

.color-orange-900:hover {
  color: rgb(189, 3, 19);
}

.bg-color-orange-900 {
  background-color: rgb(189, 3, 19);
}

.bg-color-orange-900--10 {
  background-color: rgba(189, 3, 19, 0.1);
}

.bg-color-orange-900--20 {
  background-color: rgba(189, 3, 19, 0.2);
}

.bg-color-orange-900--30 {
  background-color: rgba(189, 3, 19, 0.3);
}

.bg-color-orange-900--4 {
  background-color: rgba(189, 3, 19, 0.04);
}

.bg-color-orange-900--hover-4:hover {
  background-color: rgba(189, 3, 19, 0.04) !important;
}

.color-orange {
  color: rgb(248, 69, 46);
}

.color-orange:hover {
  color: rgb(248, 69, 46);
}

.bg-color-orange {
  background-color: rgb(248, 69, 46);
}

.bg-color-orange--10 {
  background-color: rgba(248, 69, 46, 0.1);
}

.bg-color-orange--20 {
  background-color: rgba(248, 69, 46, 0.2);
}

.bg-color-orange--30 {
  background-color: rgba(248, 69, 46, 0.3);
}

.bg-color-orange--4 {
  background-color: rgba(248, 69, 46, 0.04);
}

.bg-color-orange--hover-4:hover {
  background-color: rgba(248, 69, 46, 0.04) !important;
}

.color-orange-300 {
  color: rgb(249, 121, 84);
}

.color-orange-300:hover {
  color: rgb(249, 121, 84);
}

.bg-color-orange-300 {
  background-color: rgb(249, 121, 84);
}

.bg-color-orange-300--10 {
  background-color: rgba(249, 121, 84, 0.1);
}

.bg-color-orange-300--20 {
  background-color: rgba(249, 121, 84, 0.2);
}

.bg-color-orange-300--30 {
  background-color: rgba(249, 121, 84, 0.3);
}

.bg-color-orange-300--4 {
  background-color: rgba(249, 121, 84, 0.04);
}

.bg-color-orange-300--hover-4:hover {
  background-color: rgba(249, 121, 84, 0.04) !important;
}

.color-blue-900 {
  color: rgb(2, 48, 130);
}

.color-blue-900:hover {
  color: rgb(2, 48, 130);
}

.bg-color-blue-900 {
  background-color: rgb(2, 48, 130);
}

.bg-color-blue-900--10 {
  background-color: rgba(2, 48, 130, 0.1);
}

.bg-color-blue-900--20 {
  background-color: rgba(2, 48, 130, 0.2);
}

.bg-color-blue-900--30 {
  background-color: rgba(2, 48, 130, 0.3);
}

.bg-color-blue-900--4 {
  background-color: rgba(2, 48, 130, 0.04);
}

.bg-color-blue-900--hover-4:hover {
  background-color: rgba(2, 48, 130, 0.04) !important;
}

.color-blue {
  color: rgb(38, 55, 222);
}

.color-blue:hover {
  color: rgb(38, 55, 222);
}

.bg-color-blue {
  background-color: rgb(38, 55, 222);
}

.bg-color-blue--10 {
  background-color: rgba(38, 55, 222, 0.1);
}

.bg-color-blue--20 {
  background-color: rgba(38, 55, 222, 0.2);
}

.bg-color-blue--30 {
  background-color: rgba(38, 55, 222, 0.3);
}

.bg-color-blue--4 {
  background-color: rgba(38, 55, 222, 0.04);
}

.bg-color-blue--hover-4:hover {
  background-color: rgba(38, 55, 222, 0.04) !important;
}

.color-blue-200 {
  color: rgb(94, 129, 229);
}

.color-blue-200:hover {
  color: rgb(94, 129, 229);
}

.bg-color-blue-200 {
  background-color: rgb(94, 129, 229);
}

.bg-color-blue-200--10 {
  background-color: rgba(94, 129, 229, 0.1);
}

.bg-color-blue-200--20 {
  background-color: rgba(94, 129, 229, 0.2);
}

.bg-color-blue-200--30 {
  background-color: rgba(94, 129, 229, 0.3);
}

.bg-color-blue-200--4 {
  background-color: rgba(94, 129, 229, 0.04);
}

.bg-color-blue-200--hover-4:hover {
  background-color: rgba(94, 129, 229, 0.04) !important;
}

.color-black {
  color: rgb(25, 25, 25);
}

.color-black:hover {
  color: rgb(25, 25, 25);
}

.bg-color-black {
  background-color: rgb(25, 25, 25);
}

.bg-color-black--10 {
  background-color: rgba(25, 25, 25, 0.1);
}

.bg-color-black--20 {
  background-color: rgba(25, 25, 25, 0.2);
}

.bg-color-black--30 {
  background-color: rgba(25, 25, 25, 0.3);
}

.bg-color-black--4 {
  background-color: rgba(25, 25, 25, 0.04);
}

.bg-color-black--hover-4:hover {
  background-color: rgba(25, 25, 25, 0.04) !important;
}

.color-grey {
  color: rgb(65, 66, 71);
}

.color-grey:hover {
  color: rgb(65, 66, 71);
}

.bg-color-grey {
  background-color: rgb(65, 66, 71);
}

.bg-color-grey--10 {
  background-color: rgba(65, 66, 71, 0.1);
}

.bg-color-grey--20 {
  background-color: rgba(65, 66, 71, 0.2);
}

.bg-color-grey--30 {
  background-color: rgba(65, 66, 71, 0.3);
}

.bg-color-grey--4 {
  background-color: rgba(65, 66, 71, 0.04);
}

.bg-color-grey--hover-4:hover {
  background-color: rgba(65, 66, 71, 0.04) !important;
}

.color-grey-300 {
  color: rgb(113, 113, 113);
}

.color-grey-300:hover {
  color: rgb(113, 113, 113);
}

.bg-color-grey-300 {
  background-color: rgb(113, 113, 113);
}

.bg-color-grey-300--10 {
  background-color: rgba(113, 113, 113, 0.1);
}

.bg-color-grey-300--20 {
  background-color: rgba(113, 113, 113, 0.2);
}

.bg-color-grey-300--30 {
  background-color: rgba(113, 113, 113, 0.3);
}

.bg-color-grey-300--4 {
  background-color: rgba(113, 113, 113, 0.04);
}

.bg-color-grey-300--hover-4:hover {
  background-color: rgba(113, 113, 113, 0.04) !important;
}

.color-grey-100 {
  color: rgb(230, 230, 230);
}

.color-grey-100:hover {
  color: rgb(230, 230, 230);
}

.bg-color-grey-100 {
  background-color: rgb(230, 230, 230);
}

.bg-color-grey-100--10 {
  background-color: rgba(230, 230, 230, 0.1);
}

.bg-color-grey-100--20 {
  background-color: rgba(230, 230, 230, 0.2);
}

.bg-color-grey-100--30 {
  background-color: rgba(230, 230, 230, 0.3);
}

.bg-color-grey-100--4 {
  background-color: rgba(230, 230, 230, 0.04);
}

.bg-color-grey-100--hover-4:hover {
  background-color: rgba(230, 230, 230, 0.04) !important;
}

.color-grey-50 {
  color: rgb(236, 240, 241);
}

.color-grey-50:hover {
  color: rgb(236, 240, 241);
}

.bg-color-grey-50 {
  background-color: rgb(236, 240, 241);
}

.bg-color-grey-50--10 {
  background-color: rgba(236, 240, 241, 0.1);
}

.bg-color-grey-50--20 {
  background-color: rgba(236, 240, 241, 0.2);
}

.bg-color-grey-50--30 {
  background-color: rgba(236, 240, 241, 0.3);
}

.bg-color-grey-50--4 {
  background-color: rgba(236, 240, 241, 0.04);
}

.bg-color-grey-50--hover-4:hover {
  background-color: rgba(236, 240, 241, 0.04) !important;
}

.color-white {
  color: rgb(255, 255, 255);
}

.color-white:hover {
  color: rgb(255, 255, 255);
}

.bg-color-white {
  background-color: rgb(255, 255, 255);
}

.bg-color-white--10 {
  background-color: rgba(255, 255, 255, 0.1);
}

.bg-color-white--20 {
  background-color: rgba(255, 255, 255, 0.2);
}

.bg-color-white--30 {
  background-color: rgba(255, 255, 255, 0.3);
}

.bg-color-white--4 {
  background-color: rgba(255, 255, 255, 0.04);
}

.bg-color-white--hover-4:hover {
  background-color: rgba(255, 255, 255, 0.04) !important;
}

.color-yellow {
  color: rgb(146, 148, 60);
}

.color-yellow:hover {
  color: rgb(146, 148, 60);
}

.bg-color-yellow {
  background-color: rgb(146, 148, 60);
}

.bg-color-yellow--10 {
  background-color: rgba(146, 148, 60, 0.1);
}

.bg-color-yellow--20 {
  background-color: rgba(146, 148, 60, 0.2);
}

.bg-color-yellow--30 {
  background-color: rgba(146, 148, 60, 0.3);
}

.bg-color-yellow--4 {
  background-color: rgba(146, 148, 60, 0.04);
}

.bg-color-yellow--hover-4:hover {
  background-color: rgba(146, 148, 60, 0.04) !important;
}

.u-margin-2xsmall {
  margin: var(--space-2xsmall);
}

.u-margin-2xsmall-0 {
  margin-top: var(--space-2xsmall);
  margin-bottom: var(--space-2xsmall);
}

.u-margin-0-2xsmall {
  margin-left: var(--space-2xsmall);
  margin-right: var(--space-2xsmall);
}

.u-marginBottom-0 {
  margin-bottom: var(--space-0);
}

.u-marginBottom-2xsmall {
  margin-bottom: var(--space-2xsmall);
}

.u-marginTop-2xsmall {
  margin-top: var(--space-2xsmall);
}

.u-marginLeft-2xsmall {
  margin-left: var(--space-2xsmall);
}

.u-marginRight-2xsmall {
  margin-right: var(--space-2xsmall);
}

.u-padding-2xsmall {
  padding: var(--space-2xsmall);
}

.u-padding-2xsmall-0 {
  padding-top: var(--space-2xsmall);
  padding-bottom: var(--space-2xsmall);
}

.u-padding-0-2xsmall {
  padding-left: var(--space-2xsmall);
  padding-right: var(--space-2xsmall);
}

.u-paddingBottom-2xsmall {
  padding-bottom: var(--space-2xsmall);
}

.u-paddingTop-2xsmall {
  padding-top: var(--space-2xsmall);
}

.u-paddingLeft-2xsmall {
  padding-left: var(--space-2xsmall);
}

.u-paddingRight-2xsmall {
  padding-right: var(--space-2xsmall);
}

.u-margin-xsmall {
  margin: var(--space-xsmall);
}

.u-margin-xsmall-0 {
  margin-top: var(--space-xsmall);
  margin-bottom: var(--space-xsmall);
}

.u-margin-0-xsmall {
  margin-left: var(--space-xsmall);
  margin-right: var(--space-xsmall);
}

.u-marginBottom-xsmall {
  margin-bottom: var(--space-xsmall);
}

.u-marginTop-xsmall {
  margin-top: var(--space-xsmall);
}

.u-marginLeft-xsmall {
  margin-left: var(--space-xsmall);
}

.u-marginRight-xsmall {
  margin-right: var(--space-xsmall);
}

.u-padding-xsmall {
  padding: var(--space-xsmall);
}

.u-padding-xsmall-0 {
  padding-top: var(--space-xsmall);
  padding-bottom: var(--space-xsmall);
}

.u-padding-0-xsmall {
  padding-left: var(--space-xsmall);
  padding-right: var(--space-xsmall);
}

.u-paddingBottom-xsmall {
  padding-bottom: var(--space-xsmall);
}

.u-paddingTop-xsmall {
  padding-top: var(--space-xsmall);
}

.u-paddingLeft-xsmall {
  padding-left: var(--space-xsmall);
}

.u-paddingRight-xsmall {
  padding-right: var(--space-xsmall);
}

.u-margin-default {
  margin: var(--space-default);
}

.u-margin-default-0 {
  margin-top: var(--space-default);
  margin-bottom: var(--space-default);
}

.u-margin-0-default {
  margin-left: var(--space-default);
  margin-right: var(--space-default);
}

.u-marginBottom-default {
  margin-bottom: var(--space-default);
}

.u-marginTop-default {
  margin-top: var(--space-default);
}

.u-marginLeft-default {
  margin-left: var(--space-default);
}

.u-marginRight-default {
  margin-right: var(--space-default);
}

.u-padding-default {
  padding: var(--space-default);
}

.u-padding-default-0 {
  padding-top: var(--space-default);
  padding-bottom: var(--space-default);
}

.u-padding-0-default {
  padding-left: var(--space-default);
  padding-right: var(--space-default);
}

.u-paddingBottom-default {
  padding-bottom: var(--space-default);
}

.u-paddingTop-default {
  padding-top: var(--space-default);
}

.u-paddingLeft-default {
  padding-left: var(--space-default);
}

.u-paddingRight-default {
  padding-right: var(--space-default);
}

.u-margin-small {
  margin: var(--space-small);
}

.u-margin-small-0 {
  margin-top: var(--space-small);
  margin-bottom: var(--space-small);
}

.u-margin-0-small {
  margin-left: var(--space-small);
  margin-right: var(--space-small);
}

.u-marginBottom-small {
  margin-bottom: var(--space-small);
}

.u-marginTop-small {
  margin-top: var(--space-small);
}

.u-marginLeft-small {
  margin-left: var(--space-small);
}

.u-marginRight-small {
  margin-right: var(--space-small);
}

.u-padding-small {
  padding: var(--space-small);
}

.u-padding-small-0 {
  padding-top: var(--space-small);
  padding-bottom: var(--space-small);
}

.u-padding-0-small {
  padding-left: var(--space-small);
  padding-right: var(--space-small);
}

.u-paddingBottom-small {
  padding-bottom: var(--space-small);
}

.u-paddingTop-small {
  padding-top: var(--space-small);
}

.u-paddingLeft-small {
  padding-left: var(--space-small);
}

.u-paddingRight-small {
  padding-right: var(--space-small);
}

.u-margin-medium {
  margin: var(--space-medium);
}

.u-margin-medium-0 {
  margin-top: var(--space-medium);
  margin-bottom: var(--space-medium);
}

.u-margin-0-medium {
  margin-left: var(--space-medium);
  margin-right: var(--space-medium);
}

.u-marginBottom-medium {
  margin-bottom: var(--space-medium);
}

.u-marginTop-medium {
  margin-top: var(--space-medium);
}

.u-marginLeft-medium {
  margin-left: var(--space-medium);
}

.u-marginRight-medium {
  margin-right: var(--space-medium);
}

.u-padding-medium {
  padding: var(--space-medium);
}

.u-padding-medium-0 {
  padding-top: var(--space-medium);
  padding-bottom: var(--space-medium);
}

.u-padding-0-medium {
  padding-left: var(--space-medium);
  padding-right: var(--space-medium);
}

.u-paddingBottom-medium {
  padding-bottom: var(--space-medium);
}

.u-paddingTop-medium {
  padding-top: var(--space-medium);
}

.u-paddingLeft-medium {
  padding-left: var(--space-medium);
}

.u-paddingRight-medium {
  padding-right: var(--space-medium);
}

.u-margin-center {
  margin-left: auto;
  margin-right: auto;
}

.u-margin-8 {
  margin: 8px;
}

.u-margin-0-8 {
  margin-left: 8px;
  margin-right: 8px;
}

.u-margin-8-0 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.u-marginTop-8,
.u-marginTop-8-override.u-marginTop-8-override {
  margin-top: 8px;
}

.u-marginBottom-8,
.u-marginBottom-8-override.u-marginBottom-8-override {
  margin-bottom: 8px;
}

.u-marginRight-8,
.u-marginRight-8-override.u-marginRight-8-override {
  margin-right: 8px;
}

.u-marginLeft-8,
.u-marginLeft-8-override.u-marginLeft-8-override {
  margin-left: 8px;
}

.u-padding-8 {
  padding: 8px;
}

.u-padding-0-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.u-padding-8-0 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.u-paddingTop-8,
.u-paddingTop-8-override.u-paddingTop-8-override {
  padding-top: 8px;
}

.u-paddingBottom-8,
.u-paddingBottom-8-override.u-paddingBottom-8-override {
  padding-bottom: 8px;
}

.u-paddingRight-8,
.u-paddingRight-8-override.u-paddingRight-8-override {
  padding-right: 8px;
}

.u-paddingLeft-8,
.u-paddingLeft-8-override.u-paddingLeft-8-override {
  padding-left: 8px;
}

.u-margin-16 {
  margin: 16px;
}

.u-margin-0-16 {
  margin-left: 16px;
  margin-right: 16px;
}

.u-margin-16-0 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.u-marginTop-16,
.u-marginTop-16-override.u-marginTop-16-override {
  margin-top: 16px;
}

.u-marginBottom-16,
.u-marginBottom-16-override.u-marginBottom-16-override {
  margin-bottom: 16px;
}

.u-marginRight-16,
.u-marginRight-16-override.u-marginRight-16-override {
  margin-right: 16px;
}

.u-marginLeft-16,
.u-marginLeft-16-override.u-marginLeft-16-override {
  margin-left: 16px;
}

.u-padding-16 {
  padding: 16px;
}

.u-padding-0-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.u-padding-16-0 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.u-paddingTop-16,
.u-paddingTop-16-override.u-paddingTop-16-override {
  padding-top: 16px;
}

.u-paddingBottom-16,
.u-paddingBottom-16-override.u-paddingBottom-16-override {
  padding-bottom: 16px;
}

.u-paddingRight-16,
.u-paddingRight-16-override.u-paddingRight-16-override {
  padding-right: 16px;
}

.u-paddingLeft-16,
.u-paddingLeft-16-override.u-paddingLeft-16-override {
  padding-left: 16px;
}

.u-margin-24 {
  margin: 24px;
}

.u-margin-0-24 {
  margin-left: 24px;
  margin-right: 24px;
}

.u-margin-24-0 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.u-marginTop-24,
.u-marginTop-24-override.u-marginTop-24-override {
  margin-top: 24px;
}

.u-marginBottom-24,
.u-marginBottom-24-override.u-marginBottom-24-override {
  margin-bottom: 24px;
}

.u-marginRight-24,
.u-marginRight-24-override.u-marginRight-24-override {
  margin-right: 24px;
}

.u-marginLeft-24,
.u-marginLeft-24-override.u-marginLeft-24-override {
  margin-left: 24px;
}

.u-padding-24 {
  padding: 24px;
}

.u-padding-0-24 {
  padding-left: 24px;
  padding-right: 24px;
}

.u-padding-24-0 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.u-paddingTop-24,
.u-paddingTop-24-override.u-paddingTop-24-override {
  padding-top: 24px;
}

.u-paddingBottom-24,
.u-paddingBottom-24-override.u-paddingBottom-24-override {
  padding-bottom: 24px;
}

.u-paddingRight-24,
.u-paddingRight-24-override.u-paddingRight-24-override {
  padding-right: 24px;
}

.u-paddingLeft-24,
.u-paddingLeft-24-override.u-paddingLeft-24-override {
  padding-left: 24px;
}

.u-margin-32 {
  margin: 32px;
}

.u-margin-0-32 {
  margin-left: 32px;
  margin-right: 32px;
}

.u-margin-32-0 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.u-marginTop-32,
.u-marginTop-32-override.u-marginTop-32-override {
  margin-top: 32px;
}

.u-marginBottom-32,
.u-marginBottom-32-override.u-marginBottom-32-override {
  margin-bottom: 32px;
}

.u-marginRight-32,
.u-marginRight-32-override.u-marginRight-32-override {
  margin-right: 32px;
}

.u-marginLeft-32,
.u-marginLeft-32-override.u-marginLeft-32-override {
  margin-left: 32px;
}

.u-padding-32 {
  padding: 32px;
}

.u-padding-0-32 {
  padding-left: 32px;
  padding-right: 32px;
}

.u-padding-32-0 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.u-paddingTop-32,
.u-paddingTop-32-override.u-paddingTop-32-override {
  padding-top: 32px;
}

.u-paddingBottom-32,
.u-paddingBottom-32-override.u-paddingBottom-32-override {
  padding-bottom: 32px;
}

.u-paddingRight-32,
.u-paddingRight-32-override.u-paddingRight-32-override {
  padding-right: 32px;
}

.u-paddingLeft-32,
.u-paddingLeft-32-override.u-paddingLeft-32-override {
  padding-left: 32px;
}

.u-margin-40 {
  margin: 40px;
}

.u-margin-0-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.u-margin-40-0 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.u-marginTop-40,
.u-marginTop-40-override.u-marginTop-40-override {
  margin-top: 40px;
}

.u-marginBottom-40,
.u-marginBottom-40-override.u-marginBottom-40-override {
  margin-bottom: 40px;
}

.u-marginRight-40,
.u-marginRight-40-override.u-marginRight-40-override {
  margin-right: 40px;
}

.u-marginLeft-40,
.u-marginLeft-40-override.u-marginLeft-40-override {
  margin-left: 40px;
}

.u-padding-40 {
  padding: 40px;
}

.u-padding-0-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.u-padding-40-0 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.u-paddingTop-40,
.u-paddingTop-40-override.u-paddingTop-40-override {
  padding-top: 40px;
}

.u-paddingBottom-40,
.u-paddingBottom-40-override.u-paddingBottom-40-override {
  padding-bottom: 40px;
}

.u-paddingRight-40,
.u-paddingRight-40-override.u-paddingRight-40-override {
  padding-right: 40px;
}

.u-paddingLeft-40,
.u-paddingLeft-40-override.u-paddingLeft-40-override {
  padding-left: 40px;
}

/* Media Breakpoints */
a {
  color: inherit;
  outline: 0;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: rgb(38, 55, 222);
  text-decoration: none;
}

a:focus,
a:visited {
  text-decoration: none;
  outline: 0;
}

a.color-text-inverse:hover {
  color: var(--color-text-subdued-hover);
}

a:focus:not([class^=mat-]):not(.mat-mdc-icon-button),
select:focus,
button:focus:not([class^=mat-]):not(.mat-icon-button):focus-visible,
.focus-wrap-regular:focus {
  outline: rgb(94, 129, 229) auto 2px;
}

.focus-wrap-hard:focus {
  box-shadow: inset 0 0 0 5px rgb(94, 129, 229), 0 0 5px 2px rgb(94, 129, 229);
  outline: 0;
}

.focus-wrap-soft:focus {
  box-shadow: inset 0 0 0 1px rgb(94, 129, 229), 0 0 5px 2px rgb(94, 129, 229);
  outline: 0;
}

a:active,
select:active,
button:active {
  outline: 0;
}

.u-textCenter {
  text-align: center;
}

.u-textLeft {
  text-align: left;
}

.u-textRight {
  text-align: right;
}

.u-textJustify {
  text-align: justify;
}

.u-textUppercase {
  text-transform: uppercase;
}

.u-textLowercase {
  text-transform: lowercase;
}

.u-textCapitalize {
  text-transform: capitalize;
}

.u-paddingLeft-40 {
  padding-left: 40px;
}

.u-marginBottom-40 {
  margin-bottom: 40px;
}

.u-fontSize-138 {
  font-size: 1.38em;
}

.u-verticalAlign-middle {
  vertical-align: middle;
}

.u-verticalAlign-bottom {
  vertical-align: bottom;
}

.u-textDecoration-bold {
  font-weight: bold;
}

.u-textDecoration-lineThrough {
  text-decoration: line-through;
}

.u-opacity-30 {
  opacity: 0.3;
}

.u-opacity-50 {
  opacity: 0.5;
}

.u-opacity-80 {
  opacity: 0.8;
}

.u-overflow-auto {
  overflow: auto;
}

.u-borderRight-standard {
  border-right: 1px solid rgb(230, 230, 230);
}

.u-border-teal {
  border: 1px solid rgb(30, 188, 160);
}

.u-borderBottom-standard {
  border-bottom: 1px solid rgb(230, 230, 230);
}

.u-showScrollBar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 2px;
}

.u-showScrollBar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
}

.u-hideScrollBar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.u-hideScrollBar::-webkit-scrollbar {
  display: none;
}

.u-hover-container .u-hover-appearOnHover {
  width: 0;
  overflow: hidden;
}
.u-hover-container .u-hover-appearOnHover:active, .u-hover-container .u-hover-appearOnHover:focus, .u-hover-container .u-hover-appearOnHover:focus-within, .u-hover-container .u-hover-appearOnHover:hover {
  width: auto;
}
.u-hover-container:hover .u-hover-appearOnHover {
  width: auto;
}

.u-display-block {
  display: block;
}

.u-display-inlineBlock {
  display: inline-block;
}

.u-display-inline {
  display: inline;
}

.u-display-none {
  display: none !important;
}

.u-position-relative {
  position: relative;
}

.u-position-absolute {
  position: absolute;
}

.u-position-fixed {
  position: fixed;
}

.u-textAlign-center {
  text-align: center;
}

.u-textAlign-right {
  text-align: right;
}

.u-textAlign-left {
  text-align: left;
}

.u-border-black {
  border: 1px solid rgb(25, 25, 25);
}

.u-border-grey100 {
  border: 1px solid rgb(230, 230, 230);
}

.u-border-grey300 {
  border: 1px solid rgb(113, 113, 113);
}

.u-border-orange {
  border: 1px solid rgb(248, 69, 46);
}

.u-border-teal700 {
  border: 1px solid rgb(0, 105, 92);
}

.u-border-blue {
  border: 1px solid rgb(38, 55, 222);
}

.u-border-blue--2px {
  border: 2px solid rgb(38, 55, 222);
}

.u-border-teal700--2px {
  border: 2px solid rgb(0, 105, 92);
}

.u-border-grey300--2px {
  border: 2px solid rgb(113, 113, 113);
}

.u-border-grey100--2px {
  border: 2px solid rgb(230, 230, 230);
}

.u-border-orange--2px {
  border: 2px solid rgb(248, 69, 46);
}

.u-border-white--2px {
  border: 2px solid rgb(255, 255, 255);
}

.u-borderBottom-grey50 {
  border-bottom: 1px solid rgb(236, 240, 241);
}

.u-borderBottom-grey100 {
  border-bottom: 1px solid rgb(230, 230, 230);
}

.u-borderLeft-teal {
  border-left: 1px solid rgb(30, 188, 160);
}

.u-borderLeft-orange {
  border-left: 1px solid rgb(248, 69, 46);
}

.u-borderLeft-grey-300 {
  border-left: 1px solid rgb(113, 113, 113);
}

.u-borderLeft-grey100 {
  border-left: 1px solid rgb(230, 230, 230);
}

.u-borderRight-grey-9 {
  border-right: 1px solid var(--color-grey-9);
}

.u-borderRight-grey100 {
  border-right: 1px solid rgb(230, 230, 230);
}

.u-borderTop-grey100 {
  border-top: 1px solid rgb(230, 230, 230);
}

.u-borderTop-grey300 {
  border-top: 1px solid rgb(113, 113, 113);
}

.u-border-default {
  border: 1px solid var(--color-border-default);
}

.u-border-disabled {
  border: 1px solid var(--color-border-disabled);
}

.u-border-subdued {
  border: 1px solid var(--color-border-subdued);
}

.u-border-warning {
  border: 1px solid var(--color-border-warning);
}

.u-border-secondary {
  border: 1px solid var(--color-border-secondary);
}

.u-border-primary {
  border: 1px solid var(--color-border-primary);
}

.u-borderBottom-default {
  border-bottom: 1px solid var(--color-border-default);
}

.u-borderBottom-disabled {
  border-bottom: 1px solid var(--color-border-disabled);
}

.u-borderBottom-subdued {
  border-bottom: 1px solid var(--color-border-subdued);
}

.u-borderBottom-warning {
  border-bottom: 1px solid var(--color-border-warning);
}

.u-borderBottom-secondary {
  border-bottom: 1px solid var(--color-border-secondary);
}

.u-borderBottom-primary {
  border-bottom: 1px solid var(--color-border-primary);
}

.u-borderRadius--4px {
  border-radius: 4px;
}

.u-borderRadius--40px {
  border-radius: 40px;
}

.u-borderRadius--50 {
  border-radius: 50%;
}

.u-textUppercase {
  text-transform: uppercase;
}

.u-textLowercase {
  text-transform: lowercase;
}

.u-textCapitalize {
  text-transform: capitalize;
}

.u-textTruncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.u-pointerEvents-none {
  pointer-events: none;
}

.u-userSelect-none {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.u-whiteSpace-pre {
  white-space: pre;
}

.u-whiteSpace-preLine {
  white-space: pre-line;
}

.u-whiteSpace-noWrap {
  white-space: nowrap;
}

.u-height-100 {
  height: 100%;
}

.u-minHeight-100vh {
  min-height: 100vh;
}

.u-maxHeight-100vh {
  max-height: 100vh;
}

.u-width-100 {
  width: 100%;
}

.u-text-maxWidth {
  max-width: 545px;
}

.u-overflow-hidden {
  overflow: hidden;
}

.u-overflowX-scroll {
  overflow-x: scroll;
}

.u-wordWrap-breakWord {
  word-wrap: break-word;
}

.u-textDecoration-underline {
  text-decoration: underline;
}

.u-textDecoration-underline--hover:hover {
  text-decoration: underline;
}

.u-cursor-default {
  cursor: default;
}

.u-cursor-move {
  cursor: move;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-maxWidth--email {
  max-width: 550px;
}

.u-scrollBlurLeft:before,
.u-scrollBlurRight:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 80px;
  z-index: 1;
  pointer-events: none;
}

.u-scrollBlurLeft:before {
  left: 0;
  background: linear-gradient(to right, rgb(236, 240, 241), rgba(236, 240, 241, 0));
}

.u-scrollBlurRight:after {
  right: 0;
  background: linear-gradient(to left, rgb(236, 240, 241), rgba(236, 240, 241, 0));
}

.material-icons.md-12,
.material-icons-outlined.md-12,
.material-icons-two-tone.md-12,
.material-symbols-outlined.md-12 {
  font-size: 12px;
  height: 12px;
  width: 12px;
  min-width: 12px;
  min-height: 12px;
}
.material-icons.md-16,
.material-icons-outlined.md-16,
.material-icons-two-tone.md-16,
.material-symbols-outlined.md-16 {
  font-size: 16px;
  height: 16px;
  width: 16px;
  min-width: 16px;
  min-height: 16px;
}
.material-icons.md-18,
.material-icons-outlined.md-18,
.material-icons-two-tone.md-18,
.material-symbols-outlined.md-18 {
  font-size: 18px;
  height: 18px;
  width: 18px;
  min-width: 18px;
  min-height: 18px;
}
.material-icons.md-24,
.material-icons-outlined.md-24,
.material-icons-two-tone.md-24,
.material-symbols-outlined.md-24 {
  font-size: 24px;
  height: 24px;
  width: 24px;
  min-width: 24px;
}
.material-icons.md-32,
.material-icons-outlined.md-32,
.material-icons-two-tone.md-32,
.material-symbols-outlined.md-32 {
  font-size: 32px;
  height: 32px;
  width: 32px;
  min-width: 32px;
}
.material-icons.md-36,
.material-icons-outlined.md-36,
.material-icons-two-tone.md-36,
.material-symbols-outlined.md-36 {
  font-size: 36px;
  height: 36px;
  width: 36px;
  min-width: 36px;
}
.material-icons.md-48,
.material-icons-outlined.md-48,
.material-icons-two-tone.md-48,
.material-symbols-outlined.md-48 {
  font-size: 48px;
  height: 48px;
  width: 48px;
  min-width: 48px;
}

.material-icons-two-tone--teal700 {
  filter: invert(30%) sepia(93%) saturate(3005%) hue-rotate(158deg) brightness(99%) contrast(105%);
}
.material-icons-two-tone--blue900 {
  filter: invert(7%) sepia(60%) saturate(7469%) hue-rotate(217deg) brightness(118%) contrast(98%);
}

h1 {
  font-family: var(--heading-font-family);
  font-size: 93px;
  line-height: 1.3em;
}
h1 b {
  font-weight: 700;
}

h2 {
  font-family: var(--heading-font-family);
  font-size: 58px;
  line-height: 1.3em;
}
h2 b {
  font-weight: 700;
}

h3 {
  font-family: var(--heading-font-family);
  font-size: 46px;
  line-height: 1.3em;
}
h3 b {
  font-weight: 700;
}

h4,
.text-heading-4 {
  font-family: var(--heading-font-family);
  font-size: 33px;
  line-height: 1.3em;
}
h4 b,
.text-heading-4 b {
  font-weight: 700;
}

h5,
.text-heading-5 {
  font-family: var(--heading-font-family);
  font-size: 23px;
  line-height: 1.3em;
}
h5 b,
.text-heading-5 b {
  font-weight: 700;
}

h6,
.text-heading-6 {
  font-family: var(--heading-font-family);
  font-size: 19px;
  line-height: 1.3em;
}
h6 b,
.text-heading-6 b {
  font-weight: 700;
}

.text-subtitle-1 {
  font-family: var(--body-font-family);
  font-size: 15px;
  line-height: 130%;
}

.text-subtitle-2 {
  font-family: var(--body-font-family);
  font-size: 13px;
  font-weight: 500;
  line-height: 150%;
}

.text-body-1 {
  font-family: var(--body-font-family);
  font-size: 14px;
  line-height: 150%;
}

.text-body-2 {
  font-family: var(--body-font-family);
  font-size: 13px;
  line-height: 150%;
}

.text-caption {
  font-family: var(--body-font-family);
  font-size: 12px;
  font-weight: 300;
  line-height: 140%;
}

.text-overline {
  font-family: var(--body-font-family);
  font-size: 10px;
  line-height: 150%;
  text-transform: uppercase;
}

b {
  font-weight: 500;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

.text-heading-3xl {
  font-family: var(--heading-font-family);
  font-size: var(--heading-3xl-size);
  font-style: var(--heading-3xl-style);
  font-weight: var(--heading-3xl-weight);
  line-height: var(--heading-3xl-line-height);
}

.text-heading-2xl {
  font-family: var(--heading-font-family);
  font-size: var(--heading-2xl-size);
  font-style: var(--heading-2xl-style);
  font-weight: var(--heading-2xl-weight);
  line-height: var(--heading-2xl-line-height);
}

.text-heading-xl {
  font-family: var(--heading-font-family);
  font-size: var(--heading-xl-size);
  font-style: var(--heading-xl-style);
  font-weight: var(--heading-xl-weight);
  line-height: var(--heading-xl-line-height);
}

.text-heading-lg {
  font-family: var(--heading-font-family);
  font-size: var(--heading-lg-size);
  font-style: var(--heading-lg-style);
  font-weight: var(--heading-lg-weight);
  line-height: var(--heading-lg-line-height);
}

.text-heading-md {
  font-family: var(--heading-font-family);
  font-size: var(--heading-md-size);
  font-style: var(--heading-md-style);
  font-weight: var(--heading-md-weight);
  line-height: var(--heading-md-line-height);
}

.text-heading-sm {
  font-family: var(--heading-font-family);
  font-size: var(--heading-sm-size);
  font-style: var(--heading-sm-style);
  font-weight: var(--heading-sm-weight);
  line-height: var(--heading-sm-line-height);
}

.text-heading-xs {
  font-family: var(--heading-font-family);
  font-size: var(--heading-xs-size);
  font-style: var(--heading-xs-style);
  font-weight: var(--heading-xs-weight);
  line-height: var(--heading-xs-line-height);
}

.text-body-lg {
  font-family: var(--body-font-family);
  font-size: var(--body-lg-default-size);
  font-style: var(--body-lg-default-style);
  font-weight: var(--body-lg-default-weight);
  line-height: var(--body-lg-default-line-height);
}

.text-body-md {
  font-family: var(--body-font-family);
  font-size: var(--body-md-default-size);
  font-style: var(--body-md-default-style);
  font-weight: var(--body-md-default-weight);
  line-height: var(--body-md-default-line-height);
}

.text-body-sm {
  font-family: var(--body-font-family);
  font-size: var(--body-sm-default-size);
  font-style: var(--body-sm-default-style);
  font-weight: var(--body-sm-default-weight);
  line-height: var(--body-sm-default-line-height);
}

.text-body-xs {
  font-family: var(--body-font-family);
  font-size: var(--body-xs-default-size);
  font-style: var(--body-xs-default-style);
  font-weight: var(--body-xs-default-weight);
  line-height: var(--body-xs-default-line-height);
  letter-spacing: -0.54px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
*:focus {
  outline-color: transparent;
  outline: none;
}

.u-card {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.u-card--bottom {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.u-container {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  width: 95vw;
}

.u-bgImageCentered {
  transition: background 300ms;
  background: rgb(113, 113, 113) center no-repeat;
  background-size: cover;
}

.c-loaderOverlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgb(255, 255, 255);
  opacity: 0.8;
}

.c-loadingBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
}
@media (min-width: 768px) {
  .c-loadingBar.has-Header {
    top: 60px;
  }
}

.c-loadingBar-background {
  position: relative;
  height: 2px;
  background-color: rgb(209, 234, 230);
  z-index: 99;
}

.c-loadingBar-progress {
  display: inline;
  position: absolute;
  width: 40%;
  height: 100%;
  left: -50%;
  background-color: rgb(0, 105, 92);
  animation: loading 2s linear infinite;
}

@keyframes loading {
  from {
    left: -50%;
  }
  50% {
    left: 50%;
  }
  to {
    left: 100%;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
select::-ms-expand {
  display: none;
}

input[type=text]::-ms-clear {
  display: none;
}

.show-on-hover-element--hover .on-hover-element {
  opacity: 0;
}
.show-on-hover-element--hover .on-hover-element:focus {
  opacity: 1;
}
.show-on-hover-element--hover:hover .on-hover-element {
  opacity: 1;
}

.quill-content-iframe iframe {
  width: 100%;
  height: 280px;
}

.is-active mat-list-item {
  background-color: rgba(94, 129, 229, 0.2);
}
.is-active div.mdc-list-item__primary-text,
.is-active div.mdc-list-item__primary-text span {
  color: rgb(38, 55, 222);
}

.u-bg-color-gradient-v1 {
  background: rgb(94, 129, 229);
  background: linear-gradient(277deg, rgba(94, 129, 229, 0.57) 0%, rgb(209, 234, 230) 100%);
}

[appPreviewImages] img {
  cursor: pointer;
}

.mat-mdc-chip {
  margin: 0 8px 8px 0 !important;
  font-weight: 400 !important;
}

.mat-mdc-input-element--bg-grey.mat-input-element {
  background-color: rgb(230, 230, 230);
  padding: 16px;
}

.mat-expansion-panel-body-margin-0 .mat-expansion-panel-body {
  margin: 0 !important;
}

.mat-radio-button-label-white-space-normal label,
.mat-mdc-radio-button.mat-radio-button--longText label,
.mat-mdc-checkbox label {
  white-space: normal;
}

.mat-badge-small span.mat-badge-content {
  font-size: 10px;
}

.mat-mdc-chip-list-wrapper .mat-chip--outline.mat-chip.mat-mdc-standard-chip {
  font-family: var(--body-font-family);
  font-size: 12px;
  font-weight: 300;
  line-height: 140%;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
  background-color: rgb(255, 255, 255);
  color: rgb(65, 66, 71);
}

.mat-mdc-chip-list-wrapper .mat-chip--outline-warn.mat-chip.mat-mdc-standard-chip {
  font-family: var(--body-font-family);
  font-size: 12px;
  font-weight: 300;
  line-height: 140%;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
  background-color: rgba(249, 121, 84, 0.2);
  border: rgb(248, 69, 46);
  color: rgb(248, 69, 46);
}

.mat-mdc-chip-list-wrapper .mat-chip.mat-mdc-standard-chip {
  background-color: rgb(230, 230, 230);
}

button.mat-mdc-outlined-button,
a.mat-stroked-button {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-mdc-dialog-container {
  position: relative;
}
.mat-mdc-dialog-container .customMatDialogTitle.mdc-dialog__title {
  padding: 16px 24px;
}

textarea.mat-mdc-input-element {
  font-family: var(--body-font-family);
  font-size: 13px;
  line-height: 150%;
}

.mat-tab-group-hide-headers .mat-mdc-tab-header {
  display: none;
}

.mat-mdc-table .mat-mdc-header-cell,
.mat-mdc-table .mdc-data-table__cell {
  vertical-align: middle;
}
.mat-mdc-table.mat-mdc-table--noWrap th,
.mat-mdc-table.mat-mdc-table--noWrap tr {
  white-space: nowrap;
}

div.mat-mdc-tooltip {
  padding-top: 0;
  padding-bottom: 0;
}

.mat-select-multiple-option--hideCheckbox .mat-pseudo-checkbox {
  display: none;
}

button.mat-menu-item,
td.mat-cell,
td.mat-footer-cell,
.mat-expansion-panel-header-title,
.mat-expansion-panel-body,
.mat-expansion-panel-content,
.mat-expansion-panel-header-description,
.mat-content,
.mat-mdc-slide-toggle label,
.mat-mdc-list-base .mat-mdc-list-item .mdc-list-item__primary-text {
  font-family: var(--body-font-family);
  font-size: 13px;
  line-height: 150%;
}

.mat-mdc-form-field-hint {
  font-size: 10.5px;
}

mat-label {
  vertical-align: 1px;
}

.mat-slide-toggle-label--before .mdc-form-field {
  width: 100%;
}
.mat-slide-toggle-label--before .mdc-form-field > label {
  margin-left: inherit;
  margin-right: auto;
}

.mat-mdc-button-touch-target {
  height: auto !important;
}

.mat-mdc-icon-button .mat-icon {
  vertical-align: 6px;
}

.material-icons.md-12,
.material-icons-outlined.md-12,
.material-icons-two-tone.md-12,
.material-symbols-outlined.md-12 {
  font-size: 12px;
  height: 12px;
  width: 12px;
  min-width: 12px;
  min-height: 12px;
}
.material-icons.md-16,
.material-icons-outlined.md-16,
.material-icons-two-tone.md-16,
.material-symbols-outlined.md-16 {
  font-size: 16px;
  height: 16px;
  width: 16px;
  min-width: 16px;
  min-height: 16px;
}
.material-icons.md-18,
.material-icons-outlined.md-18,
.material-icons-two-tone.md-18,
.material-symbols-outlined.md-18 {
  font-size: 18px;
  height: 18px;
  width: 18px;
  min-width: 18px;
  min-height: 18px;
}
.material-icons.md-24,
.material-icons-outlined.md-24,
.material-icons-two-tone.md-24,
.material-symbols-outlined.md-24 {
  font-size: 24px;
  height: 24px;
  width: 24px;
  min-width: 24px;
}
.material-icons.md-32,
.material-icons-outlined.md-32,
.material-icons-two-tone.md-32,
.material-symbols-outlined.md-32 {
  font-size: 32px;
  height: 32px;
  width: 32px;
  min-width: 32px;
}
.material-icons.md-36,
.material-icons-outlined.md-36,
.material-icons-two-tone.md-36,
.material-symbols-outlined.md-36 {
  font-size: 36px;
  height: 36px;
  width: 36px;
  min-width: 36px;
}
.material-icons.md-48,
.material-icons-outlined.md-48,
.material-icons-two-tone.md-48,
.material-symbols-outlined.md-48 {
  font-size: 48px;
  height: 48px;
  width: 48px;
  min-width: 48px;
}

.material-icons-two-tone--teal700 {
  filter: invert(30%) sepia(93%) saturate(3005%) hue-rotate(158deg) brightness(99%) contrast(105%);
}
.material-icons-two-tone--blue900 {
  filter: invert(7%) sepia(60%) saturate(7469%) hue-rotate(217deg) brightness(118%) contrast(98%);
}

html {
  --mat-badge-text-font: Roboto, sans-serif;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, sans-serif;
  letter-spacing: 0.0125em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, sans-serif;
  letter-spacing: 0.009375em;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font: 400 16px / 24px Roboto, sans-serif;
  letter-spacing: 0.03125em;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 500 14px / 22px Roboto, sans-serif;
  letter-spacing: 0.0071428571em;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 400 14px / 20px Roboto, sans-serif;
  letter-spacing: 0.0178571429em;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, sans-serif;
  letter-spacing: 0.0333333333em;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 300 96px / 96px Roboto, sans-serif;
  letter-spacing: -0.015625em;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 300 60px / 60px Roboto, sans-serif;
  letter-spacing: -0.0083333333em;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 400 48px / 50px Roboto, sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 400 34px / 40px Roboto, sans-serif;
  letter-spacing: 0.0073529412em;
  margin: 0 0 64px;
}

html {
  --mat-bottom-sheet-container-text-font: Roboto, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: 0.0178571429em;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-legacy-button-toggle-label-text-line-height: 24px;
  --mat-legacy-button-toggle-label-text-size: 16px;
  --mat-legacy-button-toggle-label-text-tracking: 0.03125em;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 16px;
  --mat-standard-button-toggle-label-text-tracking: 0.03125em;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-text-font: Roboto, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-expansion-header-text-font: Roboto, sans-serif;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto, sans-serif;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.0178571429em;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-stepper-container-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-toolbar-title-text-font: Roboto, sans-serif;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0125em;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-node-text-font: Roboto, sans-serif;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

html {
  --mat-option-label-text-font: Roboto, sans-serif;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Roboto, sans-serif;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: 0.03125em;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mat-card-title-text-font: Roboto, sans-serif;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Roboto, sans-serif;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: 0.0071428571em;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

html {
  --mdc-filled-text-field-label-text-font: Roboto, sans-serif;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: 0.03125em;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Roboto, sans-serif;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: 0.03125em;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Roboto, sans-serif;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: 0.03125em;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: Roboto, sans-serif;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-trigger-text-font: Roboto, sans-serif;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: 0.03125em;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mdc-dialog-subhead-font: Roboto, sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Roboto, sans-serif;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0.0178571429em;
  --mdc-chip-label-text-weight: 400;
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: Roboto, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-font: Roboto, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-slider-label-label-text-font: Roboto, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-item-label-text-font: Roboto, sans-serif;
  --mat-menu-item-label-text-size: 16px;
  --mat-menu-item-label-text-tracking: 0.03125em;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-label-text-font: Roboto, sans-serif;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: 0.03125em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 16px / 28px Roboto, sans-serif;
  letter-spacing: 0.009375em;
}

html {
  --mat-paginator-container-text-font: Roboto, sans-serif;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-font: Roboto, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-text-button-label-text-font: Roboto, sans-serif;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0892857143em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Roboto, sans-serif;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0892857143em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Roboto, sans-serif;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0892857143em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Roboto, sans-serif;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0892857143em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}

html {
  --mdc-extended-fab-label-text-font: Roboto, sans-serif;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0892857143em;
  --mdc-extended-fab-label-text-weight: 500;
}

html {
  --mdc-snackbar-supporting-text-font: Roboto, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-header-headline-font: Roboto, sans-serif;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font: Roboto, sans-serif;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font: Roboto, sans-serif;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #2637de;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #00695c;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f8452e;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #2637de;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #2637de;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #00695c;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #00695c;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #00695c;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #00695c;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f8452e;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f8452e;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #2637de;
  --mdc-linear-progress-track-color: rgba(38, 55, 222, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #00695c;
  --mdc-linear-progress-track-color: rgba(0, 105, 92, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f8452e;
  --mdc-linear-progress-track-color: rgba(248, 69, 46, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: #2637de;
  --mdc-filled-text-field-focus-active-indicator-color: #2637de;
  --mdc-filled-text-field-focus-label-text-color: rgba(38, 55, 222, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #f8452e;
  --mdc-filled-text-field-error-focus-label-text-color: #f8452e;
  --mdc-filled-text-field-error-label-text-color: #f8452e;
  --mdc-filled-text-field-error-caret-color: #f8452e;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f8452e;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f8452e;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f8452e;
  --mdc-outlined-text-field-caret-color: #2637de;
  --mdc-outlined-text-field-focus-outline-color: #2637de;
  --mdc-outlined-text-field-focus-label-text-color: rgba(38, 55, 222, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f8452e;
  --mdc-outlined-text-field-error-focus-label-text-color: #f8452e;
  --mdc-outlined-text-field-error-label-text-color: #f8452e;
  --mdc-outlined-text-field-error-hover-label-text-color: #f8452e;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f8452e;
  --mdc-outlined-text-field-error-hover-outline-color: #f8452e;
  --mdc-outlined-text-field-error-outline-color: #f8452e;
  --mat-form-field-focus-select-arrow-color: rgba(38, 55, 222, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #f8452e;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #00695c;
  --mdc-filled-text-field-focus-active-indicator-color: #00695c;
  --mdc-filled-text-field-focus-label-text-color: rgba(0, 105, 92, 0.87);
  --mdc-outlined-text-field-caret-color: #00695c;
  --mdc-outlined-text-field-focus-outline-color: #00695c;
  --mdc-outlined-text-field-focus-label-text-color: rgba(0, 105, 92, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(0, 105, 92, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f8452e;
  --mdc-filled-text-field-focus-active-indicator-color: #f8452e;
  --mdc-filled-text-field-focus-label-text-color: rgba(248, 69, 46, 0.87);
  --mdc-outlined-text-field-caret-color: #f8452e;
  --mdc-outlined-text-field-focus-outline-color: #f8452e;
  --mdc-outlined-text-field-focus-label-text-color: rgba(248, 69, 46, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(248, 69, 46, 0.87);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(38, 55, 222, 0.87);
  --mat-select-invalid-arrow-color: rgba(248, 69, 46, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(0, 105, 92, 0.87);
  --mat-select-invalid-arrow-color: rgba(248, 69, 46, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(248, 69, 46, 0.87);
  --mat-select-invalid-arrow-color: rgba(248, 69, 46, 0.87);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #2637de;
  --mdc-chip-elevated-selected-container-color: #2637de;
  --mdc-chip-elevated-disabled-container-color: #2637de;
  --mdc-chip-flat-disabled-selected-container-color: #2637de;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #00695c;
  --mdc-chip-elevated-selected-container-color: #00695c;
  --mdc-chip-elevated-disabled-container-color: #00695c;
  --mdc-chip-flat-disabled-selected-container-color: #00695c;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f8452e;
  --mdc-chip-elevated-selected-container-color: #f8452e;
  --mdc-chip-elevated-disabled-container-color: #f8452e;
  --mdc-chip-flat-disabled-selected-container-color: #f8452e;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #174fab;
  --mdc-switch-selected-handle-color: #174fab;
  --mdc-switch-selected-hover-state-layer-color: #174fab;
  --mdc-switch-selected-pressed-state-layer-color: #174fab;
  --mdc-switch-selected-focus-handle-color: #082b8a;
  --mdc-switch-selected-hover-handle-color: #082b8a;
  --mdc-switch-selected-pressed-handle-color: #082b8a;
  --mdc-switch-selected-focus-track-color: #5f89c9;
  --mdc-switch-selected-hover-track-color: #5f89c9;
  --mdc-switch-selected-pressed-track-color: #5f89c9;
  --mdc-switch-selected-track-color: #5f89c9;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #0b6f78;
  --mdc-switch-selected-handle-color: #0b6f78;
  --mdc-switch-selected-hover-state-layer-color: #0b6f78;
  --mdc-switch-selected-pressed-state-layer-color: #0b6f78;
  --mdc-switch-selected-focus-handle-color: #034750;
  --mdc-switch-selected-hover-handle-color: #034750;
  --mdc-switch-selected-pressed-handle-color: #034750;
  --mdc-switch-selected-focus-track-color: #56a0a6;
  --mdc-switch-selected-hover-track-color: #56a0a6;
  --mdc-switch-selected-pressed-track-color: #56a0a6;
  --mdc-switch-selected-track-color: #56a0a6;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #f73e29;
  --mdc-switch-selected-handle-color: #f73e29;
  --mdc-switch-selected-hover-state-layer-color: #f73e29;
  --mdc-switch-selected-pressed-state-layer-color: #f73e29;
  --mdc-switch-selected-focus-handle-color: #f31f12;
  --mdc-switch-selected-hover-handle-color: #f31f12;
  --mdc-switch-selected-pressed-handle-color: #f31f12;
  --mdc-switch-selected-focus-track-color: #fa7d6d;
  --mdc-switch-selected-hover-track-color: #fa7d6d;
  --mdc-switch-selected-pressed-track-color: #fa7d6d;
  --mdc-switch-selected-track-color: #fa7d6d;
}

html {
  --mdc-switch-state-layer-size: 40px;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #2637de;
  --mdc-radio-selected-hover-icon-color: #2637de;
  --mdc-radio-selected-icon-color: #2637de;
  --mdc-radio-selected-pressed-icon-color: #2637de;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #2637de;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #00695c;
  --mdc-radio-selected-hover-icon-color: #00695c;
  --mdc-radio-selected-icon-color: #00695c;
  --mdc-radio-selected-pressed-icon-color: #00695c;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #00695c;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f8452e;
  --mdc-radio-selected-hover-icon-color: #f8452e;
  --mdc-radio-selected-icon-color: #f8452e;
  --mdc-radio-selected-pressed-icon-color: #f8452e;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #f8452e;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-radio-state-layer-size: 40px;
  --mat-radio-touch-target-display: block;
}

html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
}

html {
  --mdc-slider-handle-color: #2637de;
  --mdc-slider-focus-handle-color: #2637de;
  --mdc-slider-hover-handle-color: #2637de;
  --mdc-slider-active-track-color: #2637de;
  --mdc-slider-inactive-track-color: #2637de;
  --mdc-slider-with-tick-marks-inactive-container-color: #2637de;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #2637de;
  --mat-slider-hover-state-layer-color: rgba(38, 55, 222, 0.05);
  --mat-slider-focus-state-layer-color: rgba(38, 55, 222, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mat-slider-ripple-color: #00695c;
  --mat-slider-hover-state-layer-color: rgba(0, 105, 92, 0.05);
  --mat-slider-focus-state-layer-color: rgba(0, 105, 92, 0.2);
  --mdc-slider-handle-color: #00695c;
  --mdc-slider-focus-handle-color: #00695c;
  --mdc-slider-hover-handle-color: #00695c;
  --mdc-slider-active-track-color: #00695c;
  --mdc-slider-inactive-track-color: #00695c;
  --mdc-slider-with-tick-marks-inactive-container-color: #00695c;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
html .mat-warn {
  --mat-slider-ripple-color: #f8452e;
  --mat-slider-hover-state-layer-color: rgba(248, 69, 46, 0.05);
  --mat-slider-focus-state-layer-color: rgba(248, 69, 46, 0.2);
  --mdc-slider-handle-color: #f8452e;
  --mdc-slider-focus-handle-color: #f8452e;
  --mdc-slider-hover-handle-color: #f8452e;
  --mdc-slider-active-track-color: #f8452e;
  --mdc-slider-inactive-track-color: #f8452e;
  --mdc-slider-with-tick-marks-inactive-container-color: #f8452e;
  --mdc-slider-with-tick-marks-active-container-color: white;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #2637de;
  --mdc-radio-selected-hover-icon-color: #2637de;
  --mdc-radio-selected-icon-color: #2637de;
  --mdc-radio-selected-pressed-icon-color: #2637de;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #00695c;
  --mdc-radio-selected-hover-icon-color: #00695c;
  --mdc-radio-selected-icon-color: #00695c;
  --mdc-radio-selected-pressed-icon-color: #00695c;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f8452e;
  --mdc-radio-selected-hover-icon-color: #f8452e;
  --mdc-radio-selected-icon-color: #f8452e;
  --mdc-radio-selected-pressed-icon-color: #f8452e;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #2637de;
  --mdc-checkbox-selected-hover-icon-color: #2637de;
  --mdc-checkbox-selected-icon-color: #2637de;
  --mdc-checkbox-selected-pressed-icon-color: #2637de;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #2637de;
  --mdc-checkbox-selected-hover-state-layer-color: #2637de;
  --mdc-checkbox-selected-pressed-state-layer-color: #2637de;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #00695c;
  --mdc-checkbox-selected-hover-icon-color: #00695c;
  --mdc-checkbox-selected-icon-color: #00695c;
  --mdc-checkbox-selected-pressed-icon-color: #00695c;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #00695c;
  --mdc-checkbox-selected-hover-state-layer-color: #00695c;
  --mdc-checkbox-selected-pressed-state-layer-color: #00695c;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f8452e;
  --mdc-checkbox-selected-hover-icon-color: #f8452e;
  --mdc-checkbox-selected-icon-color: #f8452e;
  --mdc-checkbox-selected-pressed-icon-color: #f8452e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f8452e;
  --mdc-checkbox-selected-hover-state-layer-color: #f8452e;
  --mdc-checkbox-selected-pressed-state-layer-color: #f8452e;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #2637de;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #2637de;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
}

html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #2637de;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #2637de;
  --mat-tab-header-active-ripple-color: #2637de;
  --mat-tab-header-inactive-ripple-color: #2637de;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #2637de;
  --mat-tab-header-active-hover-label-text-color: #2637de;
  --mat-tab-header-active-focus-indicator-color: #2637de;
  --mat-tab-header-active-hover-indicator-color: #2637de;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #00695c;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #00695c;
  --mat-tab-header-active-ripple-color: #00695c;
  --mat-tab-header-inactive-ripple-color: #00695c;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #00695c;
  --mat-tab-header-active-hover-label-text-color: #00695c;
  --mat-tab-header-active-focus-indicator-color: #00695c;
  --mat-tab-header-active-hover-indicator-color: #00695c;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f8452e;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f8452e;
  --mat-tab-header-active-ripple-color: #f8452e;
  --mat-tab-header-inactive-ripple-color: #f8452e;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f8452e;
  --mat-tab-header-active-hover-label-text-color: #f8452e;
  --mat-tab-header-active-focus-indicator-color: #f8452e;
  --mat-tab-header-active-hover-indicator-color: #f8452e;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #2637de;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #00695c;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f8452e;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #00695c;
  --mdc-checkbox-selected-hover-icon-color: #00695c;
  --mdc-checkbox-selected-icon-color: #00695c;
  --mdc-checkbox-selected-pressed-icon-color: #00695c;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #00695c;
  --mdc-checkbox-selected-hover-state-layer-color: #00695c;
  --mdc-checkbox-selected-pressed-state-layer-color: #00695c;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #2637de;
  --mdc-checkbox-selected-hover-icon-color: #2637de;
  --mdc-checkbox-selected-icon-color: #2637de;
  --mdc-checkbox-selected-pressed-icon-color: #2637de;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #2637de;
  --mdc-checkbox-selected-hover-state-layer-color: #2637de;
  --mdc-checkbox-selected-pressed-state-layer-color: #2637de;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f8452e;
  --mdc-checkbox-selected-hover-icon-color: #f8452e;
  --mdc-checkbox-selected-icon-color: #f8452e;
  --mdc-checkbox-selected-pressed-icon-color: #f8452e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f8452e;
  --mdc-checkbox-selected-hover-state-layer-color: #f8452e;
  --mdc-checkbox-selected-pressed-state-layer-color: #f8452e;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-touch-target-display: block;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #2637de;
  --mat-text-button-state-layer-color: #2637de;
  --mat-text-button-ripple-color: rgba(38, 55, 222, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #00695c;
  --mat-text-button-state-layer-color: #00695c;
  --mat-text-button-ripple-color: rgba(0, 105, 92, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f8452e;
  --mat-text-button-state-layer-color: #f8452e;
  --mat-text-button-ripple-color: rgba(248, 69, 46, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #2637de;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #00695c;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f8452e;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #2637de;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #00695c;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f8452e;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #2637de;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #2637de;
  --mat-outlined-button-ripple-color: rgba(38, 55, 222, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #00695c;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #00695c;
  --mat-outlined-button-ripple-color: rgba(0, 105, 92, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f8452e;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #f8452e;
  --mat-outlined-button-ripple-color: rgba(248, 69, 46, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
  --mat-text-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #2637de;
  --mat-icon-button-state-layer-color: #2637de;
  --mat-icon-button-ripple-color: rgba(38, 55, 222, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #00695c;
  --mat-icon-button-state-layer-color: #00695c;
  --mat-icon-button-ripple-color: rgba(0, 105, 92, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f8452e;
  --mat-icon-button-state-layer-color: #f8452e;
  --mat-icon-button-ripple-color: rgba(248, 69, 46, 0.1);
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
}

html {
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #2637de;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #00695c;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #f8452e;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #2637de;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #00695c;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #f8452e;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mat-fab-touch-target-display: block;
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #00695c;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #2637de;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #00695c;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #f8452e;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: #2637de;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #00695c;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #f8452e;
  --mat-badge-text-color: white;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #2637de;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(38, 55, 222, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(38, 55, 222, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(38, 55, 222, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #2637de;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(38, 55, 222, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #00695c;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 105, 92, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(0, 105, 92, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(0, 105, 92, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(0, 105, 92, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f8452e;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(248, 69, 46, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(248, 69, 46, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(248, 69, 46, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(248, 69, 46, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #00695c;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f8452e;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #2637de;
}
.mat-icon.mat-accent {
  --mat-icon-color: #00695c;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f8452e;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #2637de;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #2637de;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #2637de;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f8452e;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f8452e;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #00695c;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #00695c;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #00695c;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f8452e;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f8452e;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f8452e;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-sort-arrow-color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #2637de;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #00695c;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f8452e;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 48px;
}

.mat-snackbar-light-theme {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #8dabd9;
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}
.mat-snackbar-light-theme .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #2637de;
  --mat-text-button-state-layer-color: #2637de;
  --mat-text-button-ripple-color: rgba(38, 55, 222, 0.1);
}
.mat-snackbar-light-theme .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #8dabd9;
  --mat-text-button-state-layer-color: #8dabd9;
  --mat-text-button-ripple-color: rgba(141, 171, 217, 0.1);
}
.mat-snackbar-light-theme .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f8452e;
  --mat-text-button-state-layer-color: #f8452e;
  --mat-text-button-ripple-color: rgba(248, 69, 46, 0.1);
}
.mat-snackbar-light-theme .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #2637de;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-snackbar-light-theme .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #8dabd9;
  --mdc-filled-button-label-text-color: #191919;
  --mat-filled-button-state-layer-color: #191919;
  --mat-filled-button-ripple-color: rgba(25, 25, 25, 0.1);
}
.mat-snackbar-light-theme .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f8452e;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-snackbar-light-theme .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #2637de;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-snackbar-light-theme .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #8dabd9;
  --mdc-protected-button-label-text-color: #191919;
  --mat-protected-button-state-layer-color: #191919;
  --mat-protected-button-ripple-color: rgba(25, 25, 25, 0.1);
}
.mat-snackbar-light-theme .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f8452e;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-snackbar-light-theme .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #2637de;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #2637de;
  --mat-outlined-button-ripple-color: rgba(38, 55, 222, 0.1);
}
.mat-snackbar-light-theme .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #8dabd9;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #8dabd9;
  --mat-outlined-button-ripple-color: rgba(141, 171, 217, 0.1);
}
.mat-snackbar-light-theme .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f8452e;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #f8452e;
  --mat-outlined-button-ripple-color: rgba(248, 69, 46, 0.1);
}

a.mat-mdc-tab-link {
  min-width: auto;
}

.mat-drawer-container {
  background-color: rgba(0, 0, 0, 0.2);
}

mat-form-field.mat-form-field-width-100 {
  display: block;
  width: 100%;
}

.mat-mdc-checkbox.mat-checkbox--longText label {
  word-break: break-word;
}

.mat-mdc-slide-toggle.mat-slide-toggle--longText {
  height: 100%;
  white-space: normal;
}
.mat-mdc-slide-toggle.mat-slide-toggle--longText label {
  white-space: normal;
}

.mat-progress-spinner--white.mat-mdc-progress-spinner circle {
  stroke: rgb(255, 255, 255);
}

.mat-progress-spinner--grey100.mat-mdc-progress-spinner circle {
  stroke: rgb(230, 230, 230);
}

.mat-elevation-z2--hover {
  transition: box-shadow 300ms ease-out;
}
.mat-elevation-z2--hover:hover {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4--hover {
  transition: box-shadow 300ms ease-out;
}
.mat-elevation-z4--hover:hover {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

html {
  --mat-badge-text-font: Inter, "sans-serif";
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font: 400 24px / 32px Inter, "sans-serif";
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font: 500 20px / 32px Inter, "sans-serif";
  letter-spacing: 0.0125em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font: 400 15px / 130% Inter, "sans-serif";
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font: 400 14px / 150% Inter, "sans-serif";
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(13px * 0.83) / 150% Inter, "sans-serif";
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(13px * 0.67) / 150% Inter, "sans-serif";
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 500 13px / 150% Inter, "sans-serif";
  letter-spacing: normal;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 400 13px / 150% Inter, "sans-serif";
  letter-spacing: normal;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 140% Inter, "sans-serif";
  letter-spacing: normal;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 300 96px / 96px Inter, "sans-serif";
  letter-spacing: -0.015625em;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 300 60px / 60px Inter, "sans-serif";
  letter-spacing: -0.0083333333em;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 400 48px / 50px Inter, "sans-serif";
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 400 34px / 40px Inter, "sans-serif";
  letter-spacing: 0.0073529412em;
  margin: 0 0 64px;
}

html {
  --mat-bottom-sheet-container-text-font: Inter, "sans-serif";
  --mat-bottom-sheet-container-text-line-height: 150%;
  --mat-bottom-sheet-container-text-size: 13px;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-label-text-font: Inter, "sans-serif";
  --mat-legacy-button-toggle-label-text-line-height: 150%;
  --mat-legacy-button-toggle-label-text-size: 14px;
  --mat-legacy-button-toggle-label-text-tracking: normal;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: Inter, "sans-serif";
  --mat-standard-button-toggle-label-text-line-height: 150%;
  --mat-standard-button-toggle-label-text-size: 14px;
  --mat-standard-button-toggle-label-text-tracking: normal;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-text-font: Inter, "sans-serif";
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 13px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-expansion-header-text-font: Inter, "sans-serif";
  --mat-expansion-header-text-size: 13px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Inter, "sans-serif";
  --mat-expansion-container-text-line-height: 150%;
  --mat-expansion-container-text-size: 13px;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 13px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 13px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-stepper-container-text-font: Inter, "sans-serif";
  --mat-stepper-header-label-text-font: Inter, "sans-serif";
  --mat-stepper-header-label-text-size: 13px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-toolbar-title-text-font: Inter, "sans-serif";
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0125em;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-node-text-font: Inter, "sans-serif";
  --mat-tree-node-text-size: 13px;
  --mat-tree-node-text-weight: 400;
}

html {
  --mat-option-label-text-font: Inter, "sans-serif";
  --mat-option-label-text-line-height: 150%;
  --mat-option-label-text-size: 14px;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Inter, "sans-serif";
  --mat-optgroup-label-text-line-height: 150%;
  --mat-optgroup-label-text-size: 14px;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mat-card-title-text-font: Inter, "sans-serif";
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Inter, "sans-serif";
  --mat-card-subtitle-text-line-height: 150%;
  --mat-card-subtitle-text-size: 13px;
  --mat-card-subtitle-text-tracking: normal;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Inter, "sans-serif";
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}

html {
  --mdc-filled-text-field-label-text-font: Inter, "sans-serif";
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Inter, "sans-serif";
  --mdc-outlined-text-field-label-text-size: 14px;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Inter, "sans-serif";
  --mat-form-field-container-text-line-height: 150%;
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 14px;
  --mat-form-field-subscript-text-font: Inter, "sans-serif";
  --mat-form-field-subscript-text-line-height: 140%;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: normal;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-trigger-text-font: Inter, "sans-serif";
  --mat-select-trigger-text-line-height: 150%;
  --mat-select-trigger-text-size: 14px;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mdc-dialog-subhead-font: Inter, "sans-serif";
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Inter, "sans-serif";
  --mdc-dialog-supporting-text-line-height: 150%;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Inter, "sans-serif";
  --mdc-chip-label-text-line-height: 150%;
  --mdc-chip-label-text-size: 13px;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 400;
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: Inter, "sans-serif";
  --mdc-form-field-label-text-line-height: 150%;
  --mdc-form-field-label-text-size: 13px;
  --mdc-form-field-label-text-tracking: normal;
  --mdc-form-field-label-text-weight: 400;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-font: Inter, "sans-serif";
  --mdc-form-field-label-text-line-height: 150%;
  --mdc-form-field-label-text-size: 13px;
  --mdc-form-field-label-text-tracking: normal;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-slider-label-label-text-font: Inter, "sans-serif";
  --mdc-slider-label-label-text-size: 13px;
  --mdc-slider-label-label-text-line-height: 150%;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-item-label-text-font: Inter, "sans-serif";
  --mat-menu-item-label-text-size: 14px;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 150%;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-label-text-font: Inter, "sans-serif";
  --mdc-list-list-item-label-text-line-height: 150%;
  --mdc-list-list-item-label-text-size: 14px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Inter, "sans-serif";
  --mdc-list-list-item-supporting-text-line-height: 150%;
  --mdc-list-list-item-supporting-text-size: 13px;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Inter, "sans-serif";
  --mdc-list-list-item-trailing-supporting-text-line-height: 140%;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 15px / 130% Inter, "sans-serif";
  letter-spacing: normal;
}

html {
  --mat-paginator-container-text-font: Inter, "sans-serif";
  --mat-paginator-container-text-line-height: 140%;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: normal;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Inter, "sans-serif";
  --mat-tab-header-label-text-size: 13px;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: 150%;
  --mat-tab-header-label-text-weight: 500;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-font: Inter, "sans-serif";
  --mdc-form-field-label-text-line-height: 150%;
  --mdc-form-field-label-text-size: 13px;
  --mdc-form-field-label-text-tracking: normal;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-text-button-label-text-font: Inter, "sans-serif";
  --mdc-text-button-label-text-size: 13px;
  --mdc-text-button-label-text-tracking: normal;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Inter, "sans-serif";
  --mdc-filled-button-label-text-size: 13px;
  --mdc-filled-button-label-text-tracking: normal;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Inter, "sans-serif";
  --mdc-outlined-button-label-text-size: 13px;
  --mdc-outlined-button-label-text-tracking: normal;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Inter, "sans-serif";
  --mdc-protected-button-label-text-size: 13px;
  --mdc-protected-button-label-text-tracking: normal;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}

html {
  --mdc-extended-fab-label-text-font: Inter, "sans-serif";
  --mdc-extended-fab-label-text-size: 13px;
  --mdc-extended-fab-label-text-tracking: normal;
  --mdc-extended-fab-label-text-weight: 500;
}

html {
  --mdc-snackbar-supporting-text-font: Inter, "sans-serif";
  --mdc-snackbar-supporting-text-line-height: 150%;
  --mdc-snackbar-supporting-text-size: 13px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-header-headline-font: Inter, "sans-serif";
  --mat-table-header-headline-line-height: 150%;
  --mat-table-header-headline-size: 13px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: normal;
  --mat-table-row-item-label-text-font: Inter, "sans-serif";
  --mat-table-row-item-label-text-line-height: 150%;
  --mat-table-row-item-label-text-size: 13px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: Inter, "sans-serif";
  --mat-table-footer-supporting-text-line-height: 150%;
  --mat-table-footer-supporting-text-size: 13px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: normal;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: "Poppins", "sans-serif";
  --mdc-dialog-subhead-size: 18px;
  --mdc-dialog-supporting-text-size: 13px;
  --mdc-dialog-supporting-text-line-height: 150%;
}

.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  font-size: 13px;
}

.mdc-button {
  border-radius: var(--border-radius-button) !important;
}

/* Media Breakpoints */
nav.c-subheader {
  display: block;
  z-index: 5;
}
@media (min-width: 768px) {
  nav.c-subheader {
    top: 60px;
    left: 0;
    right: 0;
    position: fixed;
  }
}

@media (min-width: 768px) {
  .c-subheaderPlaceholder {
    height: 50px;
  }
}

html {
  background-color: rgb(236, 240, 241);
  height: 100%;
}

body {
  background-color: rgb(236, 240, 241);
  color: rgb(25, 25, 25);
  font-family: var(--body-font-family);
  font-size: 13px;
  line-height: 150%;
}

html,
body {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

img {
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

input {
  font-family: var(--body-font-family);
  font-size: 13px;
  line-height: 150%;
}

.grecaptcha-badge {
  visibility: hidden;
}