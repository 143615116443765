@import 'colors.variables';

$blue-focus: $ttc-blue-200;

a:focus:not([class^='mat-']):not(.mat-mdc-icon-button),
select:focus,
button:focus:not([class^='mat-']):not(.mat-icon-button):focus-visible,
.focus-wrap-regular:focus {
  outline: $blue-focus auto 2px;
}

.focus-wrap-hard:focus {
  box-shadow: inset 0 0 0 5px $blue-focus, 0 0 5px 2px $blue-focus;
  outline: 0;
}

.focus-wrap-soft:focus {
  box-shadow: inset 0 0 0 1px $blue-focus, 0 0 5px 2px $blue-focus;
  outline: 0;
}

a:active,
select:active,
button:active {
  outline: 0;
}
