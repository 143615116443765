@import 'ng-zorro-antd/ng-zorro-antd.less';

@ttc-black: rgb(25, 25, 25);
@ttc-blue: rgb(38, 55, 222);
@ttc-orange: rgb(248, 69, 46);
@ttc-grey-100: rgb(230, 230, 230);
@ttc-teal-700: rgb(0, 105, 92);

@black: @ttc-black;
@font-size-base: 13px;
@font-family: Inter, 'sans-serif';
@link-color: @ttc-black;
@primary-color: @ttc-blue;
@warning-color: @ttc-orange;

@border-radius-base: var(--size-200);
@checkbox-border-radius: var(--size-100);
@segmented-label-hover-color: @primary-color;

.ant-select-show-search.ant-select .ant-select-selector {
  border-radius: 4px;
}

.ant-drawerWrapper-withCustomFooter {
  .ant-drawer-body {
    padding: 16px;
  }
}

.ant-tooltip-inner {
  font-size: 12px;
}

.ant-collapse-panel--noPadding {
  .ant-collapse-content-box {
    padding: 0;
  }
}

.ant-collapse--custom {
  .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-left: 20px;
    margin-top: -4px;
  }
  .ant-collapse > .ant-collapse-item:last-child,
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 12px;
  }
}

.ant-table-container {
  overflow: auto;

  td {
    &.ant-table-cell {
      vertical-align: middle;
    }
  }
}

.ant-table--borderRadius-default {
  .ant-table-container {
    border-radius: 16px;
    border: 1px solid var(--color-border-subdued);
  }
  .ant-table-tbody > tr:not(:last-child) > td {
    border-bottom: 1px solid var(--color-border-subdued);
  }
  .ant-table-tbody > tr > td:not(:last-child) {
    border-right: 1px solid var(--color-border-subdued);
  }
  .ant-table-tbody > tr > td {
    border: none;
  }
}

.ant-select-selection-placeholder--color-text-default {
  .ant-select-selection-placeholder {
    color: var(--color-text-default);
  }
}

.ant-segmented-item-label {
  .mat-icon {
    vertical-align: middle;
  }
}

.ant-breadcrumb {
  a,
  span {
    color: @black;
  }
}

.ant-picker-range {
  &.ant-picker-range--padding-0-1 {
    .ant-picker-range-separator {
      padding: 0 1px;
    }
  }
}

.nz-select-search-input-width nz-select-search {
  min-width: 250px;
}

.ant-datepicker--noShadow {
  .ant-picker-panel-container {
    box-shadow: none;
  }
}

.nz-skeleton-element--fullWidth {
  &.ant-skeleton-element {
    width: 100%;
  }

  .ant-skeleton-input {
    display: block;
    width: 100%;
  }
}

.ant-tabs--horizontal-border {
  .ant-tabs-nav {
    border-bottom: 1px solid var(--color-border-default);
  }
}

.ant-tabs--vertical {
  > .ant-tabs-nav {
    max-height: 500px;
    border: 1px solid @ttc-grey-100;
    border-radius: 4px;

    .ant-tabs-tab {
      margin: 0 !important;
      padding: 12px 16px;

      &.ant-tabs-tab-active {
        background-color: rgba(38, 55, 222, 0.1);
      }
    }
  }

  .ant-tabs-content-holder {
    border-left: 0;
  }
}

.create-survey-drawer {
  .ant-tabs-content {
    height: 100%;
  }
}

.nz-radio-group--background-transparent {
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before {
    background: transparent;
  }
}

.nz-carousel--dynamicHeight[nz-carousel],
.nz-carousel--dynamicHeight.ant-carousel .slick-slider .slick-track,
.nz-carousel--dynamicHeight.ant-carousel .slick-slider .slick-list,
.nz-carousel--dynamicHeight [nz-carousel-content] {
  height: auto !important;
}

.nz-option-item--no-padding {
  nz-option-item {
    padding: 0;
  }
}

.ant-upload--hover-teal-border {
  .ant-upload {
    &:not(.ant-upload-disabled) {
      &:hover {
        border-color: @ttc-teal-700;
      }
    }
  }
}
