@use '@angular/material' as mat;
@import '../../../src/stylesheets/app.colors';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  &:focus {
    outline-color: transparent;
    outline: none;
  }
}

@mixin full-bg-image-centered() {
  transition: background 300ms;
  background: $ttc-grey-300 center no-repeat;
  background-size: cover;
}

.u-card {
  @include mat.elevation(0);
  border-radius: 4px;
}

.u-card--bottom {
  @include mat.elevation(1);
}

.u-container {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  width: 95vw;
}

.u-bgImageCentered {
  @include full-bg-image-centered();
}

.c-loaderOverlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: $ttc-white;
  opacity: 0.8;
}

.c-loadingBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;

  @media (min-width: $screen-sm) {
    &.has-Header {
      top: $header-height;
    }
  }
}

.c-loadingBar-background {
  position: relative;
  height: 2px;
  background-color: $ttc-teal-100;
  z-index: 99;
}

.c-loadingBar-progress {
  display: inline;
  position: absolute;
  width: 40%;
  height: 100%;
  left: -50%;
  background-color: $ttc-teal-700;
  animation: loading 2s linear infinite;
}

@keyframes loading {
  from {
    left: -50%;
  }
  50% {
    left: 50%;
  }
  to {
    left: 100%;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

select::-ms-expand {
  display: none;
}

input[type='text']::-ms-clear {
  display: none;
}

.show-on-hover-element--hover {
  .on-hover-element {
    opacity: 0;

    &:focus {
      opacity: 1;
    }
  }

  &:hover {
    .on-hover-element {
      opacity: 1;
    }
  }
}

.quill-content-iframe iframe {
  width: 100%;
  height: 280px;
}

.is-active {
  mat-list-item {
    background-color: rgba(94, 129, 229, 0.2);
  }
  div.mdc-list-item__primary-text,
  div.mdc-list-item__primary-text span {
    color: $ttc-blue;
  }
}

.u-bg-color-gradient-v1 {
  background: rgb(94, 129, 229);
  background: linear-gradient(
    277deg,
    rgba(94, 129, 229, 0.57) 0%,
    rgba(209, 234, 230, 1) 100%
  );
}

[appPreviewImages] img {
  cursor: pointer;
}
