@import 'colors.variables';

.u-display-block {
  display: block;
}

.u-display-inlineBlock {
  display: inline-block;
}

.u-display-inline {
  display: inline;
}

.u-display-none {
  display: none !important;
}

.u-position-relative {
  position: relative;
}

.u-position-absolute {
  position: absolute;
}

.u-position-fixed {
  position: fixed;
}

.u-textAlign-center {
  text-align: center;
}

.u-textAlign-right {
  text-align: right;
}

.u-textAlign-left {
  text-align: left;
}

.u-border-black {
  border: 1px solid $ttc-black;
}

.u-border-grey100 {
  border: 1px solid $ttc-grey-100;
}

.u-border-grey300 {
  border: 1px solid $ttc-grey-300;
}

.u-border-orange {
  border: 1px solid $ttc-orange;
}

.u-border-teal700 {
  border: 1px solid $ttc-teal-700;
}

.u-border-blue {
  border: 1px solid $ttc-blue;
}

.u-border-blue--2px {
  border: 2px solid $ttc-blue;
}

.u-border-teal700--2px {
  border: 2px solid $ttc-teal-700;
}

.u-border-grey300--2px {
  border: 2px solid $ttc-grey-300;
}

.u-border-grey100--2px {
  border: 2px solid $ttc-grey-100;
}

.u-border-orange--2px {
  border: 2px solid $ttc-orange;
}

.u-border-white--2px {
  border: 2px solid $ttc-white;
}

.u-borderBottom-grey50 {
  border-bottom: 1px solid $ttc-grey-50;
}

.u-borderBottom-grey100 {
  border-bottom: 1px solid $ttc-grey-100;
}

.u-borderLeft-teal {
  border-left: 1px solid $ttc-teal;
}

.u-borderLeft-orange {
  border-left: 1px solid $ttc-orange;
}

.u-borderLeft-grey-300 {
  border-left: 1px solid $ttc-grey-300;
}

.u-borderLeft-grey100 {
  border-left: 1px solid $ttc-grey-100;
}

.u-borderRight-grey-9 {
  border-right: 1px solid var(--color-grey-9);
}

.u-borderRight-grey100 {
  border-right: 1px solid $ttc-grey-100;
}

.u-borderTop-grey100 {
  border-top: 1px solid $ttc-grey-100;
}

.u-borderTop-grey300 {
  border-top: 1px solid $ttc-grey-300;
}

.u-border-default {
  border: 1px solid var(--color-border-default);
}

.u-border-disabled {
  border: 1px solid var(--color-border-disabled);
}

.u-border-subdued {
  border: 1px solid var(--color-border-subdued);
}

.u-border-warning {
  border: 1px solid var(--color-border-warning);
}

.u-border-secondary {
  border: 1px solid var(--color-border-secondary);
}

.u-border-primary {
  border: 1px solid var(--color-border-primary);
}

.u-borderBottom-default {
  border-bottom: 1px solid var(--color-border-default);
}

.u-borderBottom-disabled {
  border-bottom: 1px solid var(--color-border-disabled);
}

.u-borderBottom-subdued {
  border-bottom: 1px solid var(--color-border-subdued);
}

.u-borderBottom-warning {
  border-bottom: 1px solid var(--color-border-warning);
}

.u-borderBottom-secondary {
  border-bottom: 1px solid var(--color-border-secondary);
}

.u-borderBottom-primary {
  border-bottom: 1px solid var(--color-border-primary);
}

.u-borderRadius--4px {
  border-radius: 4px;
}

.u-borderRadius--40px {
  border-radius: 40px;
}

.u-borderRadius--50 {
  border-radius: 50%;
}

.u-textUppercase {
  text-transform: uppercase;
}

.u-textLowercase {
  text-transform: lowercase;
}

.u-textCapitalize {
  text-transform: capitalize;
}

.u-textTruncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.u-pointerEvents-none {
  pointer-events: none;
}

.u-userSelect-none {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.u-whiteSpace-pre {
  white-space: pre;
}

.u-whiteSpace-preLine {
  white-space: pre-line;
}

.u-whiteSpace-noWrap {
  white-space: nowrap;
}

.u-height-100 {
  height: 100%;
}

.u-minHeight-100vh {
  min-height: 100vh;
}

.u-maxHeight-100vh {
  max-height: 100vh;
}

.u-width-100 {
  width: 100%;
}

.u-text-maxWidth {
  max-width: 545px;
}

.u-overflow-hidden {
  overflow: hidden;
}

.u-overflowX-scroll {
  overflow-x: scroll;
}

.u-wordWrap-breakWord {
  word-wrap: break-word;
}

.u-textDecoration-underline {
  text-decoration: underline;
}

.u-textDecoration-underline--hover {
  &:hover {
    text-decoration: underline;
  }
}

.u-cursor-default {
  cursor: default;
}

.u-cursor-move {
  cursor: move;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-maxWidth--email {
  max-width: 550px;
}

.u-scrollBlurLeft:before,
.u-scrollBlurRight:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 80px;
  z-index: 1;
  pointer-events: none;
}

.u-scrollBlurLeft:before {
  left: 0;
  background: linear-gradient(
    to right,
    rgba(236, 240, 241, 1),
    rgba(236, 240, 241, 0)
  );
}

.u-scrollBlurRight:after {
  right: 0;
  background: linear-gradient(
    to left,
    rgba(236, 240, 241, 1),
    rgba(236, 240, 241, 0)
  );
}
