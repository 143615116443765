@mixin m-text-bold() {
  font-weight: 500;
}

@mixin m-text-italic() {
  font-style: italic;
}

@mixin m-text-strong() {
  font-weight: 700;
}

@mixin mdc-typography-styles-headline1() {
  font-family: var(--heading-font-family);
  font-size: 93px;
  line-height: 1.3em;
}

@mixin mdc-typography-styles-headline2() {
  font-family: var(--heading-font-family);
  font-size: 58px;
  line-height: 1.3em;
}

@mixin mdc-typography-styles-headline3() {
  font-family: var(--heading-font-family);
  font-size: 46px;
  line-height: 1.3em;
}

@mixin mdc-typography-styles-headline4() {
  font-family: var(--heading-font-family);
  font-size: 33px;
  line-height: 1.3em;
}

@mixin mdc-typography-styles-headline5() {
  font-family: var(--heading-font-family);
  font-size: 23px;
  line-height: 1.3em;
}

@mixin mdc-typography-styles-headline6() {
  font-family: var(--heading-font-family);
  font-size: 19px;
  line-height: 1.3em;
}

@mixin mdc-typography-styles-subtitle1() {
  font-family: var(--body-font-family);
  font-size: 15px;
  line-height: 130%;
}

@mixin mdc-typography-styles-subtitle2() {
  font-family: var(--body-font-family);
  font-size: 13px;
  font-weight: 500;
  line-height: 150%;
}

@mixin mdc-typography-styles-body1() {
  font-family: var(--body-font-family);
  font-size: 14px;
  line-height: 150%;
}

@mixin mdc-typography-styles-body2() {
  font-family: var(--body-font-family);
  font-size: 13px;
  line-height: 150%;
}

@mixin mdc-typography-styles-button() {
  font-family: var(--body-font-family);
  font-size: 13px;
  line-height: 150%;
}

@mixin mdc-typography-styles-overline() {
  font-family: var(--body-font-family);
  font-size: 10px;
  line-height: 150%;
  text-transform: uppercase;
}

@mixin mdc-typography-styles-caption() {
  font-family: var(--body-font-family);
  font-size: 12px;
  font-weight: 300;
  line-height: 140%;
}

@mixin ttc-typography-styles-heading-3xl {
  font-family: var(--heading-font-family);
  font-size: var(--heading-3xl-size);
  font-style: var(--heading-3xl-style);
  font-weight: var(--heading-3xl-weight);
  line-height: var(--heading-3xl-line-height);
}

@mixin ttc-typography-styles-heading-2xl {
  font-family: var(--heading-font-family);
  font-size: var(--heading-2xl-size);
  font-style: var(--heading-2xl-style);
  font-weight: var(--heading-2xl-weight);
  line-height: var(--heading-2xl-line-height);
}

@mixin ttc-typography-styles-heading-xl {
  font-family: var(--heading-font-family);
  font-size: var(--heading-xl-size);
  font-style: var(--heading-xl-style);
  font-weight: var(--heading-xl-weight);
  line-height: var(--heading-xl-line-height);
}

@mixin ttc-typography-styles-heading-lg {
  font-family: var(--heading-font-family);
  font-size: var(--heading-lg-size);
  font-style: var(--heading-lg-style);
  font-weight: var(--heading-lg-weight);
  line-height: var(--heading-lg-line-height);
}

@mixin ttc-typography-styles-heading-md {
  font-family: var(--heading-font-family);
  font-size: var(--heading-md-size);
  font-style: var(--heading-md-style);
  font-weight: var(--heading-md-weight);
  line-height: var(--heading-md-line-height);
}

@mixin ttc-typography-styles-heading-sm {
  font-family: var(--heading-font-family);
  font-size: var(--heading-sm-size);
  font-style: var(--heading-sm-style);
  font-weight: var(--heading-sm-weight);
  line-height: var(--heading-sm-line-height);
}

@mixin ttc-typography-styles-heading-xs {
  font-family: var(--heading-font-family);
  font-size: var(--heading-xs-size);
  font-style: var(--heading-xs-style);
  font-weight: var(--heading-xs-weight);
  line-height: var(--heading-xs-line-height);
}

@mixin ttc-typography-styles-body-lg {
  font-family: var(--body-font-family);
  font-size: var(--body-lg-default-size);
  font-style: var(--body-lg-default-style);
  font-weight: var(--body-lg-default-weight);
  line-height: var(--body-lg-default-line-height);
}

@mixin ttc-typography-styles-body-md {
  font-family: var(--body-font-family);
  font-size: var(--body-md-default-size);
  font-style: var(--body-md-default-style);
  font-weight: var(--body-md-default-weight);
  line-height: var(--body-md-default-line-height);
}

@mixin ttc-typography-styles-body-sm {
  font-family: var(--body-font-family);
  font-size: var(--body-sm-default-size);
  font-style: var(--body-sm-default-style);
  font-weight: var(--body-sm-default-weight);
  line-height: var(--body-sm-default-line-height);
}

@mixin ttc-typography-styles-body-xs {
  font-family: var(--body-font-family);
  font-size: var(--body-xs-default-size);
  font-style: var(--body-xs-default-style);
  font-weight: var(--body-xs-default-weight);
  line-height: var(--body-xs-default-line-height);
  letter-spacing: -0.54px;
}
