.material-icons,
.material-icons-outlined,
.material-icons-two-tone,
.material-symbols-outlined {
  &.md-12 {
    font-size: 12px;
    height: 12px;
    width: 12px;
    min-width: 12px;
    min-height: 12px;
  }
  &.md-16 {
    font-size: 16px;
    height: 16px;
    width: 16px;
    min-width: 16px;
    min-height: 16px;
  }
  &.md-18 {
    font-size: 18px;
    height: 18px;
    width: 18px;
    min-width: 18px;
    min-height: 18px;
  }
  &.md-24 {
    font-size: 24px;
    height: 24px;
    width: 24px;
    min-width: 24px;
  }
  &.md-32 {
    font-size: 32px;
    height: 32px;
    width: 32px;
    min-width: 32px;
  }
  &.md-36 {
    font-size: 36px;
    height: 36px;
    width: 36px;
    min-width: 36px;
  }
  &.md-48 {
    font-size: 48px;
    height: 48px;
    width: 48px;
    min-width: 48px;
  }
}
.material-icons-two-tone {
  &--teal700 {
    filter: invert(30%) sepia(93%) saturate(3005%) hue-rotate(158deg)
      brightness(99%) contrast(105%);
  }
  &--blue900 {
    filter: invert(7%) sepia(60%) saturate(7469%) hue-rotate(217deg)
      brightness(118%) contrast(98%);
  }
}
